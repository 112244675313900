@import './colors.less';

label {
  color: @labelColor;
  &.required-label {
    position: relative;
    display: block;
    &::after {
      content: "*";
      text-align: right;
      position: absolute;
      right: 5px;
      font-size: 18px;
      color: @red;
    }
  }
}

.form-group {
  .form-errors {
    display: none;
    padding: 5px;
    font-size: 14px;
    margin-top: 5px;
    ul {
      padding-left: 14px;
    }
  }

  &.errored-group {
    label {
      color: @red;
    }

    input {
      border-color: @red;
    }

    select {
      border-color: @red;
    }

    .form-errors {
      display: block;
    }
  }
}

.button-styles {
  font-size: 15px;
  text-transform: uppercase;
  border: none;
  border-top:0px;
  border-radius: 2px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-bottom: 10px;
  -webkit-transition: color 0.15s ease-in-out;
  transition: color 0.15s ease-in-out;    
  -webkit-transition: background-color 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out;    
  // padding: 0 45px 0 45px;
  color: @white;
  &.shadow-btn {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.23921568627451);
  }
  &.orange-btn {
    background-color: @orange;
    height: 54px;
    &:hover {
      background-color: @blue;
      color: #FFFFFF;
    }
    &.no-hover-btn {
      &:hover {
        background-color:  @orange;
      }
    }
  }
  &.blue-btn {
    background-color: @blue;
    height: 54px;
    &:hover {
      background-color: @orange;
      color: #FFFFFF;
    }
    &.no-hover-btn {
      &:hover {
        background-color:  @blue;
      }
    }
  }

  &.red-btn {
    background-color: @red;
    height: 54px;
    &:hover {
      background-color: @white;
      color: @red;
      border: 1px solid @red;
    }
    &.no-hover-btn {
      &:hover {
        background-color:  @red;
      }
    }
  }

  &.green-btn {
    background-color: @green;
    height: 54px;
    &:hover {
      background-color: rgba(4, 0, 68, 1);
      color: white;
    }
    &.no-hover-btn {
      &:hover {
        background-color:  @orange;
      }
    }
  }

  &.yellow-btn {
    background-color: @yellow;
    height: 54px;
    color: #444;
    &:hover {
      background-color: rgba(4, 0, 68, 1);
      color: white;
    }
    &.no-hover-btn {
      &:hover {
        background-color:  @orange;
      }
    }
  }

  &.quickfilter,
  &.skinny {
    margin-right: 5px;
    height: 25px;
    &.active-filter {
      .blue-btn;
      height: 25px;
    }
  }
}

button {
  .button-styles;
}

a.nav-btn {
  .button-styles;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  padding-top: 16px;
}
