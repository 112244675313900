@import "../../node_modules/bootstrap/less/bootstrap.less";
@import "../../node_modules/font-awesome/less/font-awesome.less";
@import "../../node_modules/toastr/toastr.less";
@import './colors.less';
@import "./fonts.less";
@import "./vive-icons.less";
@import (inline) "../../node_modules/angular-ui-bootstrap/dist/ui-bootstrap-csp.css";
@import (inline) "../../node_modules/ui-select/dist/select.css";
@import (inline) "../../node_modules/textangular/dist/textAngular.css";
@import (inline) "../../node_modules/nvd3/build/nv.d3.css";
@import (inline) "../../node_modules/nvd3/build/nv.d3.css";
@import (inline) "../..//node_modules/angular-loading-bar/build/loading-bar.css";
@import "./modal.less";
@import "./breadcrumbs.less";
@import "./table.less";
// @import "./signUp.less";
@import "./form.less";
@import "./dashboard.less";
@import "./alertCenter.less";
@import "./header.less";
@import "./dropdowns.less";
@import "./registration/compliance.less";
@import "./admin/adjustPricingTable.less";
@import "./admin/vendor/adminTools.less";
@import "./shared/vendorDetails.less";
@import "./clientSiteDetails.less";
@import "./viveCertificate.less";
@import "./viveGrid.less";
@import "./vendorSignUp.less";
@import "./contractManagementWizard.less";
// REMOVE WEIRD OUTLINES
*:focus {
  outline: none;
}
/*DO NOT REMOVE THIS STYLE.  THIS IS USED TO PROPERLY RENDER IE VIEWPORTS FOR MOBILE.*/
@-ms-viewport {
  width: device-width;
}
/*TOASTR OVERRIDES*/
#toast-container > .toast {
  background-image: none !important;
}

#toast-container {
  > div {
    opacity: 1;
  }
  > .toast-top-center {
    > div {
      width: 525px;
    }
  }
  > .toast {
    .toast-message {
      ul {
        margin-left: 0;
        padding-left: 16px;
        margin-top: 10px;
      }
    }
    &:before {
      position: fixed;
      font-family: FontAwesome, 'FontAwesome';
      font-size: 24px;
      line-height: 25px;
      float: left;
      color: #FFF;
      padding-right: 0.5em;
      margin: auto 0.5em auto -1.5em;
    }
  }
  > .toast-warning {
    background-color: @warning;
    text-shadow: 0px 1px 1px #000, 0px 1px 0px #000, 0px 1px 0px #000;
    &:before {
      content: "\f06a";
    }
  }
  > .toast-error {
    text-shadow: 0px 1px 1px #000, 0px 1px 0px #000, 0px 1px 0px #000;
    &:before {
      content: "\f057";
    }
  }
  > .toast-info {
    &:before {
      content: "\f05a";
    }
  }
  > .toast-success {
    text-shadow: 0px 1px 1px #000, 0px 1px 0px #000, 0px 1px 0px #000;
    &:before {
      content: "\f00c";
    }
  }
}
/* ==========================================================================
   Index:
  1. BASE STYLES
    - Base
    - Typography
  2. STRUCTURE
    - Header
    - Content
    - Footer
  3. MAIN NAVIGATION
    - Primary
    - Mobile Menu
  4. PARTIALS
    - Register
  5. MISCELLANOUS
  6. MOBILE MEDIA QUERIES
   ========================================================================== */
/* ==========================================================================
  1. BASE STYLES
   ========================================================================== */

/* ==========================================================================
  Base
   ========================================================================== */
 body {
   margin: 0;
 }

/* ==========================================================================
  Typography
   ========================================================================== */
body {
  font-family: "Rubik", "Rubik", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #333333;
}
a {
  color: #00B7FF;
}

.ui-select-bootstrap {
  .ui-select-choices {
    overflow-x: scroll;
  }
  .ui-select-no-choice {
    overflow-x: inherit;
  }
}

select.select-input-lg {
  height: 46px;
  padding: 10px 10px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.select-input-lg {
  .ui-select-bootstrap>.ui-select-match>.btn,
  .form-control:focus {
      height: 46px;
      padding: 10px 10px;
      font-size: 18px;
      line-height: 1.3333333;
      border-radius: 6px;
  }
}

/* ==========================================================================
  2. STRUCTURE
   ========================================================================== */

/* ==========================================================================
  Login / Password Reset
   ========================================================================== */
.login, .password-reset, .vendor-login {
  max-width: 40%;
  margin: 5% auto;
  -webkit-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.5);
  border-radius: 0;
  h1 {
    text-align: center;
    margin-bottom: 10%;
  }
  .wide-input {
    border-radius: 0;
  }
  ul.login-links {
    margin: 0;
    padding: 0;
    text-align: center;
    li {
      list-style-type: none;
      display: inline-block;
      &::after {
        content: "|";
        margin: 0 5px;
        font-size: 14px;
        font-weight: 300;
      }
      &:last-child::after {
        content: "";
        margin: 0;
      }
    }
  }
  .btn {
    width: 100%;
    margin: 5% 0;
    outline: none;
  }
}
.vendor-login {
  margin: 15% auto 5%;
  max-width: 100%;
  min-height: 470px;
  h1 {
    margin-bottom: 5%;
  }
  .panel-body.right-login, .panel-body .left-login {
    max-width: 65%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }
  .panel-body input[type="text"], .panel-body input[type="email"] {
    margin-top: 7%;
  }
}
  /* ==========================================================================
  Client Sign-Up
  ========================================================================== */
.client-sign-up {
  margin-top: 5%;
  .sign-up {
    .header-image {
      margin-bottom: 2%;
    }
    h1 {
      margin-top: 0;
    }
    h4 {
      font-size: initial;
      font-weight: 600;
      margin: 6% 0 2%;
    }
    .required-a {
      float: none;
      margin-right: 0;
      font-weight: 500;
    }
    .wide-input {
      border-radius: 0;
      box-shadow: 0 0 0 0;
      border: 1px solid #e0e0e0;
    }
    &.confirmation {
      margin-bottom: 10%;
      h1 {
        margin-bottom: 2%;
      }
    }
  }
}
/* ==========================================================================
Insurance Upload
========================================================================== */
.insurance-upload {
  .panel {
    border-radius: 0;
    border: 0 none;
    box-shadow: 0 0 0 0;
    .btn {
      border-radius: 0;
      width: 100%;
      height: 45px;
    }
    .site-modal .ui-select-bootstrap > .ui-select-match > .btn, .site-modal .ui-select-bootstrap > input.ui-select-search {
      height: 35px;
    }
  }
}
/* ==========================================================================
Register
  ========================================================================== */
.login, .password-reset, .vendor-login {
  margin: 5% auto;
  -webkit-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.5);
  border-radius: 0;
  h1 {
    text-align: center;
    margin-bottom: 10%;
  }
  h2 {
    text-align: center;
    margin: 5% 0;
    font-weight: 600;
  }
  .wide-input {
    border-radius: 0;
  }
  ul.login-links {
    margin: 0;
    padding: 0;
    text-align: center;
    li {
      list-style-type: none;
      display: inline-block;
      &::after {
        content: "|";
        margin: 0 5px;
        font-size: 14px;
        font-weight: 300;
      }
      &:last-child::after {
        content: "";
        margin: 0;
      }
    }
  }
}
.password-reset {
  max-width: 40%;
}
.login {
  max-width: 90%;
  min-height: 540px;
  input {
    max-width: 80%;
    margin: 0 auto;
  }
  .btn {
    max-width: 80%;
    display: block;
    margin: 5% auto;
  }
  h2 {
    margin: 5% 0;
    text-align: center;
    font-weight: 600;
  }
  p {
    text-align: center;
  }
  &.right-panel {
    text-align: center;
    // min-height: 0;
    margin: 5% auto;
    h4 {
      font-weight: 600;
    }
    .btn {
      line-height: 1;
      padding: 20px 0;
      text-transform: uppercase;
      margin-top: 110px;
    }
  }
  .seperator {
    border-bottom: #999 1px solid;
    outline: none;
    border-top: 0 none;
    border-left: 0 none;
    border-right: 0 none;
    max-width: 90%;
  }
}
.vendor-login {
  margin: 15% auto 5%;
  max-width: 100%;
  min-height: 565px;
  .btn {
    width: 100%;
    margin: 5% auto;
    outline: none;
    display: block;
    padding: 20px 0;
    line-height: 1;
    text-transform: uppercase;
  }
  h1 {
    margin-bottom: 5%;
  }
  .panel-body.right-login {
    max-width: 65%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    margin-top: 10px;
  }
}
  /* ==========================================================================
  Client Sign-Up
  ========================================================================== */
.client-sign-up {
  margin-top: 5%;
  .sign-up {
    .header-image {
      margin-bottom: 2%;
    }
    h1 {
      margin-top: 0;
    }
    h4 {
      font-size: initial;
      font-weight: 600;
      margin: 6% 0 2%;
    }
    .required-a {
      float: none;
      margin-right: 0;
      font-weight: 500;
    }
    .wide-input {
      border-radius: 0;
      box-shadow: 0 0 0 0;
      border: 1px solid #e0e0e0;
    }
    &.confirmation {
      margin-bottom: 10%;
      h1 {
        margin-bottom: 2%;
      }
    }
  }
}
  /* ==========================================================================
  Register
  ========================================================================== */
.register {
  .top-row{
    padding: 20px;
  }
  h1 {
    font-size: 32px;
  }

  h2 {
    font-weight: 600;
    font-size: 18px;
  }
  h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 35px 0;
  }
  h4 {
    font-size: 12px;
    font-weight: 600;
  }
  .inner-shadowbox {
    h2 {
      font-weight: 400;
      font-size: 14px;
    }
  }
  .btn.btn-orange, .btn.btn-black {
    max-width: 310px;
    width: 100%;
    padding: 15px 0;
    margin: 20px 0;
    text-transform: uppercase;
  }
  .btn.btn-black {
    -webkit-transition: color 0.15s ease-in-out;
    transition: color 0.15s ease-in-out;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
    &:hover {
      background-color: @orange;
      color: #ffffff;
    }
  }
  .btn.prev, .btn.next {
    width: 100%;
  }
  .table {
    tr {
      td:first-child {
        width: 25%;
      }
    }
  }
  button.small{
    padding: 10px 35px;
  }
  div.required {
    font-size: inherit;
    padding-left: 0;
  }
  .red-asterisk {
    color: #ff0000;
    font-size: 20px;
  }
  .form {
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    select {
      padding: 7px;
      &.error-input{
        border: 1px solid red;
      }
      &.error-highlight {
        border: 1px solid red;
        background-color: #ffcccb;
      }
      border: 1px solid #d0d0d0;
      outline: none;
      -webkit-transition: box-shadow 0.15s ease-in-out;
      transition: box-shadow 0.15s ease-in-out;
      &:hover {
        box-shadow: 0 0 3px 1px @orange inset;
      }
      &:active {
        box-shadow: 0 0 3px 1px @orange inset;
      }
      &:focus {
        box-shadow: 0 0 3px 1px @orange inset;
      }
    }
    .error-label{
      color: red;
    }

    input.full,
    select.full {
      width: 95%;
      height: 35px;
    }

    label {
      display: block;
    }
    .required {
      color: #ff0000;
      font-size: 10px;
      padding-left: 10px;
    }
    .ui-select-bootstrap > .ui-select-match > .btn, .ui-select-bootstrap > input.ui-select-search {
      display: block;
      margin: 0;
      font-weight: normal;
      text-align: center;
      cursor: pointer;
      background-image: none;
      border: 1px solid #d0d0d0;
      white-space: nowrap;
      padding: 10px 0;
      font-size: inherit;
      line-height: 0;
      border-radius: 0;
      -webkit-transition: box-shadow 0.15s ease-in-out;
      transition: box-shadow 0.15s ease-in-out;
      min-height: 36px;
      box-shadow: 0 0 0 0;
      &:hover {
        box-shadow: 0 0 3px 1px @orange inset;
        background-color: #FFFFFF;
      }
      &:active {
        box-shadow: 0 0 3px 1px @orange inset;
      }
      &:focus {
        box-shadow: 0 0 3px 1px @orange inset;
      }
      .ui-select-match-text {
        line-height: 1;
        span {
          text-align: center;
        }
      }
    }
  }
  .step-four{
    h3 {
      margin: 20px 0px;
    }
    .fa-check{
      font-size: 25px;
    }
  }
  .owner-add {
    p {
      margin: 5px 0;
    }
    .btn.btn-orange {
      margin: 10px 0;
    }
    .orange-text {
      font-weight: 600;
      color: @orange;
    }
    .red-text {
      font-weight: 500;
      font-size: 14px;
      color: @red;
    }
  }
  .owner-table, .insurance-table, .license-table {
    margin-top: 20px;
  }
  .table {
    border-collapse: collapse;
    .orange-bottom th {
      border-bottom: 2px solid @orange;
      text-align: center;
    }
    .table-el {
      text-align: center;
      .fa {
        font-size: 18px;
        cursor: pointer;
        margin: 0 2px;
      }
    }
  }
  .license-table {
      tr td, tr td:first-child {
        max-width: 350px;
      }
  }
  .vendor-questionnaire, .vendor-acknowledge {
    // border: 1px solid #999999;
    padding: 0 15px;
    min-height: 250px;
    h2 {
      text-align: center;
      margin-bottom: 10%;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      .fa {
        margin-left: 5%;
      }
    }
  }
  .vendor-acknowledge {
    text-align: center;
    div {
      margin: 14% 0;
      i {
        cursor: pointer;
      }
    }
  }
  .shadowbox {
    .trade-licensing-wizard {
      .btn.btn-orange-thin, .btn.btn-black-thin {
        display: block;
        width: 100%;
      }
      .btn {
        max-width: 450px;
        display: block;
        margin-top: 0;
      }
      .zipcode-box {
        border: 1px solid #E0E0E0;
        padding: 10px;
        max-width: 465px;
        box-shadow: 0px 0px 7px -3px rgba(0, 0, 0, 0.5);
      }
      ul.tradeList {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          display: inline-block;
          background-color: @orange;
          color: @white;
          padding: 5px 10px;
          margin: 3px 5px 3px 0px;
          .removeTrade {
            font-size: 18px;
            vertical-align: middle;
            margin-left: 10px;
            font-weight: bold;
          }
        }
      }
    }
    .ui-select-multiple.ui-select-bootstrap {
      padding: 3px 5px 0;
      min-height: 35px;
      border-radius: 0;
      box-shadow: 0 0 0;
      border: 1px solid #d0d0d0;
      .ui-select-choices-row {
        &.active > span {
          background-color: @orange;
          color: @white;
        }
        span {
          &:hover {
            background-color: @orange;
            color: @white;
          }
          &:focus {
            color: @white;
            background-color: @orange;
          }
        }
      }
      .ui-select-match-item.btn.btn-xs {
        color: @white !important;
        background-color: @blue;
        border: 0 none transparent;
        padding: 5px;
        font-size: 14px;
        border-radius: 0 0 0 0;
        .close {
          font-size: 24px;
          line-height: 20px;
          color: white;
          opacity: 1;
          font-weight: normal;
          text-shadow: 0 0 0 transparent;
          margin-left: 10px;
        }
        &:hover {
          background-color: @orange;
        }
      }
    }
    .tab-header-row {
      // border: 1px solid #999999;
      border-bottom: 0;
      .tab-header {
        border-bottom: 0;
        color: @white;
        background-color: @blue;
        padding: 8px 0;
        font-size: 12px;
        font-weight: normal;
        -webkit-transition: background-color 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out;
        &.active {
          background-color: @orange;
        }
        &:hover {
          background-color: @orange;
        }
      }
    }
    .tab-content-area {
      .panel.panel-default {
        box-shadow: 0 0 0 0;
        border-radius: 0;
        border-top: 0;
        border-color: #999999;
        border-collapse: collapse;
        padding-top: 10px;
        .panel-body {
          .ta-toolbar {
            .btn-group {
              margin: 5px 0;
              button.btn, div.btn {
                border-radius: 0;
              }
            }
          }
          .ta-scroll-window.form-control {
            border: 1px solid #999999;
            box-shadow: 0 0 0 0;
            border-radius: 0;
            margin-top: 10px;
            div {
              outline: none;
            }
          }
        }
      }
    }
  }
  .required-a {
    float: none;
    margin-left: 5px;
    color: #ff0000;
    font-size: 20px;
    margin-right: 0;
    line-height: 0;
  }
}
// VENDOR REGISTRATION TRACKER
.vendor-registration-tracker {
  .tracker {
    width: 80%;
    margin: 25px auto 0;
    .step {
      display: inline-block;
      max-width: 19.6%;
      width: 100%;
      color: #757575;
      -webkit-transition: color 0.15s ease-in-out;
      -moz-transition: color 0.15s ease-in-out;
      -o-transition: color 0.15s ease-in-out;
      -ms-transition: color 0.15s ease-in-out;
      transition: color 0.15s ease-in-out;
      i {
        font-size: 4em;
        text-align: center;
        display: block;
      }
      span {
        text-align: center;
        display: block;
        margin-bottom: 15px;
        font-weight: 700;
      }
      .completion-bar {
        background-color: #757575;
        -webkit-transition: background-color 0.15s ease-in-out;
        -moz-transition: background-color 0.15s ease-in-out;
        -o-transition: background-color 0.15s ease-in-out;
        -ms-transition: background-color 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out;
        padding: 5px 0;
        width: 100%;
        position: relative;
        &::before {
          -webkit-transition: border 0.15s ease-in-out;
          -moz-transition: border 0.15s ease-in-out;
          -o-transition: border 0.15s ease-in-out;
          -ms-transition: border 0.15s ease-in-out;
          transition: border 0.15s ease-in-out;
          content: '';
          border: 8px solid;
          width: 30px;
          height: 30px;
          border-radius: 20px;
          background-color: #fff;
          position: absolute;
          top: -10px;
          left: -15px;
        }
        &::after {
          -webkit-transition: border 0.15s ease-in-out;
          -moz-transition: border 0.15s ease-in-out;
          -o-transition: border 0.15s ease-in-out;
          -ms-transition: border 0.15s ease-in-out;
          transition: border 0.15s ease-in-out;
          content: '';
          border: 8px solid;
          width: 30px;
          display: block;
          position: absolute;
          right: -19px;
          top: -10px;
          border-radius: 20px;
          height: 30px;
          background-color: #FFFFFF;
          z-index: 10;
        }
      }
      &.active {
        color: @blue;
        .completion-bar {
          background-color: @blue;
          &::before {
            background-color: #fff;
          }
          &::after {
            border: 8px solid #757575;
            background-color: #FFFFFF;
          }
        }
      }
      &.complete {
        color: @vive-green;
        .completion-bar {
          background-color: @vive-green;
          &::before {
            background-color: @vive-green;
            width: 31px;
            height: 31px;
            top: -11px;
          }
          &::after {
            z-index: 0;
            border: 8px solid #757575;
            background-color: #FFFFFF;
          }
          &.last {
            &::after {
              background-color: @vive-green;
              border: 8px solid @vive-green;
            }
          }
        }
      }
      &.skipped {
        color: @dark-red;
      }
      .status-text {
        margin-top: 10px;
      }
    }
  }
}
// VIVE REVIEW TRACKER
.vive-review-tracker {
  .tracker {
    width: 80%;
    margin: 0 auto;
    .step {
      display: inline-block;
      max-width: 19.6%;
      width: 100%;
      color: #757575;
      -webkit-transition: color 0.15s ease-in-out;
      -moz-transition: color 0.15s ease-in-out;
      -o-transition: color 0.15s ease-in-out;
      -ms-transition: color 0.15s ease-in-out;
      transition: color 0.15s ease-in-out;
      i {
        font-size: 4.5em;
        text-align: center;
        display: block;
      }
      span {
        text-align: center;
        display: block;
        margin-bottom: 15px;
        font-weight: 700;
      }
      .completion-bar {
        background-color: #757575;
        padding: 3px 0;
        width: 100%;
        position: relative;
        -webkit-transition: background-color 0.15s ease-in-out;
        -moz-transition: background-color 0.15s ease-in-out;
        -o-transition: background-color 0.15s ease-in-out;
        -ms-transition: background-color 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out;
        &::before {
          -webkit-transition: border 0.15s ease-in-out;
          -moz-transition: border 0.15s ease-in-out;
          -o-transition: border 0.15s ease-in-out;
          -ms-transition: border 0.15s ease-in-out;
          transition: border 0.15s ease-in-out;
          content: '';
          border: 5px solid;
          width: 20px;
          height: 20px;
          border-radius: 20px;
          background-color: #fff;
          position: absolute;
          top: -7px;
          left: -14px;
        }
        &::after {
          -webkit-transition: border 0.15s ease-in-out;
          -moz-transition: border 0.15s ease-in-out;
          -o-transition: border 0.15s ease-in-out;
          -ms-transition: border 0.15s ease-in-out;
          transition: border 0.15s ease-in-out;
          content: '';
          border: 5px solid;
          width: 20px;
          display: block;
          position: absolute;
          right: -9px;
          top: -7px;
          border-radius: 20px;
          height: 20px;
          background-color: #FFFFFF;
          z-index: 10;
        }
      }
      &.reviewing {
        color: @blue;
        .completion-bar {
          background-color: @blue;
          &::before {
            background-color: #fff;
          }
          &::after {
            z-index: 0;
            border: 8px solid #757575;
            background-color: #FFFFFF;
          }
        }
      }
      &.passed {
        color: @vive-green;
        .completion-bar {
          background-color: @vive-green;
          &::before {
            background-color: @vive-green;
            width: 21px;
            height: 21px;
            top: -8px;
          }
          &::after {
            z-index: 0;
            border: 8px solid #757575;
            background-color: #FFFFFF;
          }
          &.last {
            &::after {
              background-color: @vive-green;
              border: 8px solid @vive-green;
            }
          }
        }
      }
      &.failed {
        color: @dark-red;
        .completion-bar {
          background-color: @dark-red;
          &::before {
            background-color: @dark-red;
            width: 21px;
            height: 21px;
            top: -8px;
          }
          &::after {
            z-index: 0;
            border: 8px solid #757575;
            background-color: #FFFFFF;
          }
          &.last {
            &::after {
              background-color: @dark-red;
              border: 8px solid @dark-red;
            }
          }
        }
      }
      &.resolving {
        color: @orange;
        .completion-bar {
          background-color: @orange;
          &::before {
            background-color: @orange;
            width: 21px;
            height: 21px;
            top: -8px;
          }
          &::after {
            z-index: 0;
            border: 8px solid #757575;
            background-color: #FFFFFF;
          }
          &.last {
            &::after {
              background-color: @orange;
              border: 8px solid @orange;
            }
          }
        }
      }
      &.missing {
        color: @orange;
        .completion-bar {
          background-color: @orange;
          &::before {
            background-color: #fff;
            width: 21px;
            height: 21px;
          }
          &::after {
            z-index: 0;
            border: 8px solid #757575;
            background-color: #FFFFFF;
          }
        }
      }
      &.review-needed {
        color: @orange;
        .completion-bar {
          background-color: @orange;
          &::before {
            background-color: #fff;
            width: 21px;
            height: 21px;
          }
          &::after {
            z-index: 0;
            border: 8px solid #757575;
            background-color: #FFFFFF;
          }
          &.last.client-registered {
            &::after {
              background-color: @orange;
              border: 8px solid @orange;
            }
          }
        }
      }
      .status-text {
        margin-top: 10px;
      }
    }
  }
}
.minority-form-right {
  .upload-btn {
    border: 1px solid #D0D0D0;
    padding: 2.5%;
  }
}
.simple-checkout {
  p {
    margin: 10px 0;
    line-height: 15px;
  }
  .paynow {
    width: 100%;
    form {
      display: inline;
      input[type=submit] {
        background: @orange;
        color: @white;
        padding: 6px;
        width: 100%;
        border: 0 none;
        font-size: 12px;
        text-transform: uppercase;
        -webkit-transition: color 0.15s ease-in-out;
        transition: color 0.15s ease-in-out;
        -webkit-transition-property: color, background-color;
        transition-property: color, background-color;
        &:hover {
          background: @blue;
          color: @white;
        }
      }
    }
  }
}
.submit-category{
  font-weight: bold;
  text-decoration: underline;
  margin: 0 10px 0 10px;
  color: #333333;
  font-size: 11px;
  cursor: pointer;
  &.selected{
    color: @orange;
  }
}
.submit-category:hover{
  color: @orange;
}
.large-button{
  height: 120px;
  width: 143px;
  color: rgb(136, 196, 65);
  border: none;
  border-radius: 0;
  font-size: 15px;
  background: #041E39;
  -webkit-box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);

  h1{
    padding-top: 10px;
    font-size: 72px;
  }
  .foot{
    color: white;
  }
}

.btn-orange{
  width: 200px;
  height: 52px;
  background-color:@orange;
  color: #FFFFFF;
  line-height: 20px;
  -webkit-box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  border: none;
  border-radius: 0;
    -webkit-transition: color 0.15s ease-in-out;
    transition: color 0.15s ease-in-out;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
  &:hover {
    background-color: @blue;
    color: #ffffff;
  }
  &:active {
    background-color: @blue;
    color: #ffffff;
  }
  &:focus {
    background-color: @blue;
    color: #ffffff;
  }
}
.btn-orange-thin{
  padding-top: 15px;
  margin-top: 10px;
  padding-bottom: 15px;
  margin-bottom: 10px;
  line-height: 10px;
  background-color: @orange;
  color: #FFFFFF;
  -webkit-box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  border: none;
  border-radius: 0;
  cursor: pointer;
  -webkit-transition: color 0.15s ease-in-out;
  transition: color 0.15s ease-in-out;
  &.small{
    padding: 8px 5px 8px 5px;
    font-size: 15px;
    margin-top: 8px;
    margin-bottom: 8px;
    line-height: 8px;
  }
  &.sm-col{
    text-align: center;
    font-size: 12px;
    margin: 0 3px 0 3px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  &.padded{
    padding-left: 10px;
    padding-right: 10px;
  }
  &.half-width{
    width: 50%;
  }
  &.quarter-width{
    width: 25%;
  }
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
  &:hover {
    background-color: @blue;
    color: #FFFFFF;
  }
}
.btn-black{
  width: 200px;
  height: 52px;
  background-color: @blue;
  color: #FFFFFF;
  line-height: 20px;
  -webkit-box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  border: none;
  border-radius: 0;
  -webkit-transition: background-color 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out;
  &:hover{
    background-color: @orange;
    color: @white;
  }
  &:active{
    background-color: @orange;
    color: @white;
  }
  &:focus{
    background-color: @orange;
    color: @white;
  }
}
.btn-gray{
  width: 200px;
  height: 52px;
  background-color: grey;
  color: @white;
  line-height: 20px;
  -webkit-box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  border: none;
  border-radius: 0;
  -webkit-transition: background-color 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out;
  &:hover{
    background-color: @orange;
    color: @white;
  }
  &:active{
    background-color: @orange;
    color: @white;
  }
  &:focus{
    background-color: @orange;
    color: @white;
  }
}
.btn-black-thin{
  padding-top: 15px;
  margin-top: 10px;
  padding-bottom: 15px;
  margin-bottom: 10px;
  line-height: 10px;
  background-color: @blue;
  color: #FFFFFF;
  -webkit-box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  border: none;
  border-radius: 0;
  cursor: pointer;
  -webkit-transition: background-color 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out;
  &.small{
    padding: 8px 5px 8px 5px;
    font-size: 15px;
    margin-top: 8px;
    margin-bottom: 8px;
    line-height: 8px;
  }
  &.sm-col{
    text-align: center;
    font-size: 12px;
    margin: 0 3px 0 3px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  &.wide{
    padding-left: 10px;
    padding-right: 10px;
  }
  &:hover{
    background-color: @orange;
    color: @white;
  }
  &:active {
    color: @white;
  }
  &.active {
    background-color: @orange;
    color: @white;
  }
  &.half-width{
    width: 50%;
  }
  &.quarter-width{
    width: 25%;
  }
}
.btn-green-thin{
  padding-top: 15px;
  margin-top: 10px;
  padding-bottom: 15px;
  margin-bottom: 10px;
  line-height: 10px;
  background-color: @green;
  color: #FFFFFF;
  -webkit-box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  border: none;
  border-radius: 0;
  cursor: pointer;
  -webkit-transition: background-color 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out;
  &.small{
    padding: 8px 5px 8px 5px;
    font-size: 15px;
    margin-top: 8px;
    margin-bottom: 8px;
    line-height: 8px;
  }
  &.sm-col{
    text-align: center;
    font-size: 12px;
    margin: 0 3px 0 3px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  &.wide{
    padding-left: 10px;
    padding-right: 10px;
  }
  &:hover{
    background-color: @blue;
    color: @white;
  }
  &:active {
    color: @white;
  }
  &:hover {
    color: @white;
  }
}
.btn-maroon{
  width: 200px;
  height: 52px;
  background-color: rgba(153, 0, 0, 1);
  color: #FFFFFF;
  line-height: 20px;
  -webkit-box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  border: none;
  border-radius: 0;
}
.btn-maroon:hover{
  background-color: rgba(2, 98, 164, 1);
}
.btn-red-thin {
  padding-top: 15px;
  margin-top: 10px;
  padding-bottom: 15px;
  margin-bottom: 10px;
  line-height: 10px;
  background-color: @red;
  color: #FFFFFF;
  -webkit-box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.2);
  border: none;
  border-radius: 0;
  cursor: pointer;
  -webkit-transition: background-color 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out;
  &.small{
    padding: 8px 5px 8px 5px;
    font-size: 15px;
    margin-top: 8px;
    margin-bottom: 8px;
    line-height: 8px;
  }
  &.sm-col{
    text-align: center;
    font-size: 12px;
    margin: 0 3px 0 3px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  &.wide{
    padding-left: 10px;
    padding-right: 10px;
  }
  &:hover {
    background-color: @blue;
    color: @white;
  }
}
//nav tab styling for tabs within panels.
.panel {
  .nav-tabs {
    border-bottom: 1px solid #e0e0e0;
    margin: 15px;
  }
  .nav-tabs > li > a  {
    border-radius: 0;
  }
  .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    color: @blue;
    cursor: default;
    border: 1px solid #e0e0e0;
    border-bottom-color: transparent;
    font-weight: 700;
  }
}
// replaces our card styling
.panel-default {
  box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.5);
  border-radius: 0;
}
.shadowbox {
  padding: 10px;
  cursor: default;
}
.top-margin-small{
  margin-top: 15px;
}
.inner-shadowbox{
  height: 190px;
  padding: 36px 20px;
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.3);
  text-align: center;
  &.blue {
    color: @blue;
    -webkit-transition: color 0.15s ease-in-out;
    transition: color 0.15s ease-in-out;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
    &.active {
      color: @white;
      background-color: @blue;
    }
  }
  &.orange {
    color: @orange;
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
    &.active {
      color: @white;
      background-color: @orange;
    }
  }
}
.small-margin{
  margin-bottom: 10px;
}
.small-bold{
  font-size: 13px;
  color: #5A6673;
  font-weight: 700;
}
.small-bold-2{
  font-size: 14px;
  font-weight: 700;
  color: #333333;
}
.small-plain{
  font-size: 13px;
}
.small-padding{
  padding: 5px;
}

.small-header {
  font-size: 10px;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
  border-bottom: 2px solid black;
  &.active{
    border-bottom: 2px solid lightgray;
    color: rgb(136, 196, 65);
  }
}

.icon-btn {
  .btn-orange;
  width: 30px;
  height:30px;
  padding: 5px;
  font-size: 20px;
  box-shadow: none;
  border-radius: 5px;
}

.tab-header {
  .small-header;
  font-size: 10px;
  font-weight: bold;
}

.med-margin{
  margin-bottom: 20px;
}
.med-side-margin{
  margin: 0 20px 0 20px;
}
.med-height{
  height: 35px;
}
.med-plain{
    font-family: 'Rubik', 'Rubik';
    font-weight: 400;
    font-size: 18px;
    &.orangeHover{
      cursor: pointer;
      color: @orange;
    }
}
.med-2-plain{
  font-size: 20px;
  font-family: 'Rubik', 'Rubik';
  font-weight: 400;
  font-style: normal;
}
.med-bold{
  font-family: "Rubik";
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
}
.med-2-bold{
  font-family: "Rubik";
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
}
.med-2-green-btn{
  font-weight: 700;
  font-family: "Roboto-Bold", "Roboto Bold", "Roboto";
  font-style: normal;
  font-size: 20px;
  color: rgb(0, 0, 0);
  text-align: center;
  line-height: 24px;
  cursor: pointer;
  &.active{
    color: rgb(136, 196, 65);
  }
}
.upload-btn{
  text-align: center;
  cursor: pointer;
  padding-top: 2.5%;
  .image-uploader {
    border: 1px solid #d0d0d0;
    max-width: 95%;
    -webkit-transition: box-shadow 0.15s ease-in-out;
    transition: box-shadow 0.15s ease-in-out;
    &:hover {
      box-shadow: 0 0 3px 1px @orange inset;
    }
    &:active {
      box-shadow: 0 0 3px 1px @orange inset;
    }
    &:focus {
      box-shadow: 0 0 3px 1px @orange inset;
    }
  }

}
.row-spacing{
  .row{
    margin-bottom: 10px;
  }
}
.required {
  color: #ff0000;
  font-size: 10px;
  padding-left: 30px;
  text-align: center;
}
.required-a{
  float: right;
  margin-right: 20px;
  color: #ff0000;
  font-size: 20px;
}
.site-modal {
  .row{
    margin-top: 10px;
  }
  .has-error {
    color: #FF0000 !important;
    border-color: #FF0000 !important;
  }
  &.submit-vendor {
    .row {
      margin-top: 0;
    }
  }
  h2 {
    margin: 10px 0;
  }
  .owner-upload {
    border: 1px solid #d0d0d0;
    text-align: center;
    padding: 20px;
    margin-top: 35px;
    cursor: pointer;
  }
  input, select {
    border: 1px solid #d0d0d0;
  }
  .ui-select-bootstrap > .ui-select-match > .btn, .ui-select-bootstrap > input.ui-select-search {
    display: block;
    font-weight: normal;
    text-align: center;
    cursor: pointer;
    background-image: none;
    border: 1px solid #d0d0d0;
    white-space: nowrap;
    padding: 10px 0 10px 10px;
    font-size: inherit;
    line-height: 0;
    border-radius: 0;
    -webkit-transition: box-shadow 0.15s ease-in-out;
    transition: box-shadow 0.15s ease-in-out;
    min-height: 36px;
    &:hover {
      box-shadow: 0 0 3px 1px @orange inset;
      background-color: #FFFFFF;
    }
    &:active {
      box-shadow: 0 0 3px 1px @orange inset;
    }
    &:focus {
      box-shadow: 0 0 3px 1px @orange inset;
    }
    .ui-select-match-text {
      line-height: 1;
      span {
        text-align: center;
      }
    }
  }
  .existing-vendors-search {
    margin: 10% auto;
    width: 40%;
    box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
    padding: 1.5%;
  }
  .add-new-vendor {
    .ui-select-bootstrap > .ui-select-match > .btn, .ui-select-bootstrap > input.ui-select-search {
      margin: 0;
      min-height: 35px;
    }
  }
  .input-group-btn .btn.btn-default {
    border-radius: 0;
    height: 35px;
  }
  .input-group-btn {
    .btn {
      border-radius: 0 !important;
      line-height: 1.5;
      &.btn-orange-datepicker {
        color: #FFFFFF;
        background-color: @orange;
        &:hover {
          background-color: @blue;
        }
      }
    }
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
  }
  // Override till I can get responsive modals in here - callnutt@surgeforward.com
  .container {
    h3 {
      margin-top: 18px;
    }
    .row {
      margin-top: 0px;
    }
  }

  .required {
      color: #ff0000;
      font-size: 10px;
      padding-left: 10px;
  }
  .required-a {
    margin-left: 5px;
    color: #ff0000;
    font-size: 20px;
    line-height: 14px;
    display: inline-block;
  }
  .error-label{
      color: red;
  }
  .error-input{
    border: 1px solid red;
  }
  .orange-text {
    font-weight: 600;
    color: @orange;
  }
  ol {
    margin: 5% 0;
    padding-left: 3%;
  }
  ul.trade-list, ul.state-list {
    padding: 0;
  }
  .btn-orange-thin, .btn-black-thin {
    width: 100%;
    padding-top: 15px;
    margin-top: 10px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    line-height: 10px;
  }
  .btn-orange-thin:hover, .btn-black-thin:hover {
    color: #FFFFFF;
  }
  .btn-short-thin {
    width: 125px;
    height: 50px;
  }
  .license-info {
    .btn-orange-thin {
      padding: 13px 0;
      margin-top: 0;
    }
    .ui-select-multiple.ui-select-bootstrap {
      padding: 3px 5px 0;
      min-height: 35px;
      border-radius: 0;
      box-shadow: 0 0 0;
      border: 1px solid #d0d0d0;
      .ui-select-choices-row {
        &.active > span {
          background-color: @orange;
          color: @white;
        }
        span {
          &:hover {
            background-color: @orange;
            color: @white;
          }
          &:focus {
            color: @white;
            background-color: @orange;
          }
        }
      }
      .ui-select-match-item.btn.btn-xs {
        color: @white !important;
        background-color: @blue;
        border: 0 none transparent;
        padding: 5px;
        font-size: 14px;
        border-radius: 0 0 0 0;
        .close {
          font-size: 24px;
          line-height: 20px;
          color: white;
          opacity: 1;
          font-weight: normal;
          text-shadow: 0 0 0 transparent;
          margin-left: 10px;
        }
        &:hover {
          background-color: @orange;
        }
      }
    }
  }
  &.upload-modal {
    max-width: 911px;
    margin: 0 auto;
  }
  &.add-site-modal {
    .ui-select-bootstrap > .ui-select-match > .btn, .ui-select-bootstrap > input.ui-select-search {
      margin-top: 0px;
    }
    vive-state-selector {
      padding: 0;
    }
    .wide-input.ng-touched.ng-invalid {
      border: 1px solid #FF0000 !important;
    }
    vive-state-selector:hover {
      box-shadow: 0 0 0 0;
    }
    .preview-box {
      font-size: 115px;
      text-align: center;
      line-height: 0;
      cursor: pointer;
    }
  }
  .document-list {
    .document-item.row {
      margin-top: 0;
      padding: 10px 0;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      i.fa {
        vertical-align: middle;
      }
      &:hover {
        background-color: @orange;
        color: #FFF;
        cursor: pointer;
      }
    }
  }
}
.seperator {
    border-bottom: #999 1px solid;
    outline: none;
    border-top: 0 none;
    border-left: 0 none;
    border-right: 0 none;
  &.med{
    width: 90%;
  }
}
.edit-licenses {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 18px;
    font-weight: 600;
  }
  .table {
    .orange-bottom {
      th {
        border-bottom: 2px solid orange;
        text-align: center;
      }
    }
    .table-el {
      text-align: center;
      .fa {
        font-size: 18px;
        cursor: pointer;
        margin: 0 2px;
      }
    }
  }
  .shadowbox {
    .trade-licensing-wizard {
      label {
        display: block;
      }
      .btn.btn-orange-thin .btn.btn-black-thin {
        display: block;
        width: 100%;
      }
      .btn {
        max-width: 460px;
        display: block;
        margin-top: 0;
        width: 100%;
      }
      .zipcode-box {
        border: 1px solid #E0E0E0;
        padding: 10px;
        max-width: 465px;
        box-shadow: 0px 0px 7px -3px rgba(0, 0, 0, 0.5);
      }
      ul.tradeList {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          display: inline-block;
          background-color: @orange;
          color: @white;
          padding: 5px 10px;
          margin: 3px 5px 3px 0px;
          .removeTrade {
            font-size: 18px;
            vertical-align: middle;
            margin-left: 10px;
            font-weight: bold;
          }
        }
      }
    }
    .ui-select-multiple.ui-select-bootstrap {
      padding: 3px 5px 0;
      min-height: 35px;
      border-radius: 0;
      box-shadow: 0 0 0;
      border: 1px solid #d0d0d0;
      .ui-select-choices-row {
        &.active > span {
          background-color: @orange;
          color: @white;
        }
        span {
          &:hover {
            background-color: @orange;
            color: @white;
          }
          &:focus {
            color: @white;
            background-color: @orange;
          }
        }
      }
      .ui-select-match-item.btn.btn-xs {
        color: @white !important;
        background-color: @blue;
        border: 0 none transparent;
        padding: 5px;
        font-size: 14px;
        border-radius: 0 0 0 0;
        .close {
          font-size: 24px;
          line-height: 20px;
          color: white;
          opacity: 1;
          font-weight: normal;
          text-shadow: 0 0 0 transparent;
          margin-left: 10px;
        }
        &:hover {
          background-color: @orange;
        }
      }
    }
    .tab-header-row {
      // border: 1px solid #999999;
      border-bottom: 0;
      .tab-header {
        border-bottom: 0;
        color: @white;
        background-color: @blue;
        padding: 8px 0;
        font-size: 12px;
        font-weight: normal;
        -webkit-transition: background-color 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out;
        &.active {
          background-color: @orange;
        }
        &:hover {
          background-color: @orange;
        }
      }
    }
    .tab-content-area {
      .panel.panel-default {
        box-shadow: 0 0 0 0;
        border-radius: 0;
        border-top: 0;
        border-color: #999999;
        border-collapse: collapse;
        padding-top: 10px;
        .panel-body {
          .ta-toolbar {
            .btn-group {
              margin: 5px 0;
              button.btn, div.btn {
                border-radius: 0;
              }
            }
          }
          .ta-scroll-window.form-control {
            border: 1px solid #999999;
            box-shadow: 0 0 0 0;
            border-radius: 0;
            margin-top: 10px;
            div {
              outline: none;
            }
          }
        }
      }
    }
  }
}
.client-my-reports {
  h3.title {
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: 1px solid;
    margin-bottom: 30px;
  }
  .panel {
    margin-top: 10px;
    border: 1px solid #E0E0E0;
    .panel-body {
      .dashboard-graph {
        border: 1px solid #E0E0E0;
        margin: 10px 0;
        padding-bottom: 10px;
        h5 {
          text-align: center;
          background-color: @blue;
          margin: 0 0 15px;
          padding: 8px 0;color: #FFFFFF;
          text-transform: uppercase;
          font-size: 13px;
        }
        .legend-container {
          text-align: center;
          ul {
            text-align: center;
            margin: 0;
            padding: 0;
            list-style: none;
            li {
              display: inline-block;
              margin-left: 10px;
              span.chart-legend {
                display: inline-block;
                padding: 3px 7px;
                margin-right: 5px;
                vertical-align: middle;
                max-width: 7%;
              }
              span.chart-label-legend-text {
                display: inline-block;
                max-width: 93%;
                vertical-align: middle;
                font-size: 9px;
              }
              &:first-child {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}
.client-vendor-details,
.client-site-details,
.vendor-client-details,
.vendor-site-details,
.insurance-details,
.admin-vendor-details,
.admin-client-details,
.client-my-reports, .admin-my-reports {
  .seperator {
    border-bottom: #999 1px solid;
    outline: none;
    border-top: 0 none;
    border-left: 0 none;
    border-right: 0 none;
  }
  .vendor-information, .site-information, .client-information, .admin-vendor-information {
    word-break: break-all;
  }
  .admin-vendor-information {
    .row {
      div {
        font-size: 12px;
      }
    }
  }
  .report-list {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        border-top: 1px solid #D9D9D9;
        padding: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-weight: 600;
          i.fa {
            color: @blue;
            cursor: pointer;
            vertical-align: middle;
            -webkit-transition: color 0.15s ease-in-out;
            transition: color 0.15s ease-in-out;
            &:first-child {
              margin-right: 10px;
            }
            &:hover {
              color: #FFA500;
            }
          }
        }
        &:last-child {
          border-bottom: 1px solid #D9D9D9;
        }
      }
    }
  }
  .vendor-details-tabs, .site-details-tabs, .client-details-tabs {
    .red-status {
      .red-status();
    }
    .tab-header-row {
      // border: 1px solid #999999;
      border-bottom: 0;
      .tab-header {
        border-bottom: 0;
        color: @white;
        background-color: @blue;
        padding: 3px 0;
        font-size: 12px;
        font-weight: normal;
        -webkit-transition: background-color 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out;
        &.active {
          background-color: @orange;
        }
        &:hover {
          background-color: @orange;
        }
      }
    }
    .details-panel {
      border: 1px solid #e0e0e0;
      border-top: 0 none;
      padding-bottom: 5%;
      margin-bottom: 3%;
      .vendor-admin-section {
        ul {
          padding-left: 15px;
          list-style-type: square;
        }
        button.btn {
          width: 100%;
        }
      }
      .vendor-compliance-style {
        .compliance-text {
          text-align: left;
          margin: 0 !important;
          padding: 8px 0;
        }
        .compliance-row {
          & > div {
            margin-bottom: 10px;
            select, input {
              margin: 5px 0;
            }
          }
          .compliance-pass-fail {
            text-align: left;
            i.fa {
              padding: 11px 0;
            }
          }
          .row-border-bottom {
            border-bottom: 1px solid #e0e0e0;
            border-left: 1px solid #e0e0e0;
            border-right: 1px solid #e0e0e0;
            margin: 0 2%;
            max-width: 96%;
            p {
              margin: 10px 0;
            }
          }
          .row-header {
            background-color: @blue;
            padding-top: 1%;
            padding-bottom: 1%;
            max-width: 96%;
            margin: 0 2%;
            label {
              color: #FFF;
            }
          }
        }
        .vendorCompliance.admin {
          margin-top: 15px;
          label {
            display: block;
            text-align: center;
          }
          .row {
            margin-top: 15px;
            margin-bottom: 15px;
            p {
              text-align: center;
            }
          }
        }
      }
      .review-container {
        max-width: 85%;
        margin: 0 auto;
      }
    }
    .vendor-review {
      hr.seperator {
        margin: 10px 0 20px;
      }
      h3 {
        font-weight: 700;
        font-size: 22px;
        margin: 30px 0 0;
      }
      h4 {
        font-size: 21px;
        margin-bottom: 0;
      }
      .green {
        color: #01c801;
      }
      .red {
        color: #d80000;
      }
      i.fa-star, i.fa-star-o {
        margin: 0 5px;
        color: @orange;
      }
      .numOfReviews {
        font-size: 12px;
      }
      .overall-rating {
        max-width: 45%;
        margin: 30px auto;
        text-align: center;
      }
      .specific-ratings {
        h3 {
          text-align: center;
        }
        .vendor-customer-service, .vendor-professionalism {
          span {
            i.fa.fa-star, i.fa.fa-star-o {
              cursor: default;
            }
          }
        }
        .user-ratings {
          .user-ratings-header {
            padding: 10px;
            text-align: center;
          }
          .user-item-container {
            border: 1px solid #999999;
            overflow-y: auto;
            overflow-x: hidden;
            height: 480px;
            .user-item {
              text-align: center;
              padding: 10px;
              cursor: pointer;
              border-top: 1px solid #E0E0E0;
              -webkit-transition: all 0.15s ease-in-out;
              transition: all 0.15s ease-in-out;
              &:last-child {
                border-bottom: 1px solid #E0E0E0;
              }
              &:hover {
                background-color: #041E39;
                border-color: #041E39;
                color: #FFFFFF;
              }
              span {
                display: block;
                font-size: 4px;
                i {
                  margin: 0 2px;
                  color: @orange;
                  cursor: default;
                }
              }
            }
          }
        }
      }
    }
  }
}
.insurance-details.site-modal {
  .table-filter {
    display: none;
  }
  .vendor-details-tabs {
    max-width: 96.5%;
    margin: 0 auto;
    display: block;
    float: none;
    .details-panel {
      padding-top: 2%;
    }
  }

  .vendor-details-tabs-bar {
    max-width: 97.5%;
    margin: 0 auto;
    display: block;
    float: none;
    padding-left: 0px;
    padding-right: 0px;
    .details-panel {
      padding-top: 2%;
    }
    .btn-black-thin {
      padding-top: 10px;
      padding-bottom: 10px;
      box-shadow: none;
    }
  }
  .vendor-details-panel {
    padding-top: 2%;
  }

  .row {
    margin-top: 0;
  }
}
.client-my-company, .vendor-my-company, .manage-user-detail, .site-detail-panel {
  .required-a {
    float: none;
    margin: 0;
    line-height: 0;
  }
  .ui-select-bootstrap > .ui-select-match > .btn, .ui-select-bootstrap > input.ui-select-search {
    display: block;
    margin: 8px 0 0 0;
    font-weight: normal;
    text-align: center;
    cursor: pointer;
    background-image: none;
    border: 1px solid #d0d0d0;
    white-space: nowrap;
    padding: 10px 0;
    font-size: inherit;
    line-height: 0;
    border-radius: 0;
    -webkit-transition: box-shadow 0.15s ease-in-out;
    transition: box-shadow 0.15s ease-in-out;
    min-height: 36px;
    box-shadow: 0 0 0 0;
    &:hover {
      box-shadow: 0 0 3px 1px @orange inset;
      background-color: #FFFFFF;
    }
    &:active {
      box-shadow: 0 0 3px 1px @orange inset;
    }
    &:focus {
      box-shadow: 0 0 3px 1px @orange inset;
    }
    .ui-select-match-text {
      line-height: 1;
      span {
        text-align: center;
      }
    }
  }
  .ui-select-bootstrap > .ui-select-match > .btn, .ui-select-bootstrap > input.ui-select-search {
    margin-top: 0px;
  }
  vive-state-selector {
    padding: 0;
  }
  vive-state-selector:hover {
    box-shadow: 0 0 0 0;
  }
  .pick-logo {
    text-align: center;
    padding: 10px;
    cursor: pointer;
    border: 1px solid #cccccc;
    margin-top: 24px;
  }
  .btn {
    width: 100%;
    &.btn-orange-thin {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    &.btn-black-thin {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    &.btn-red-thin {
      background-color: @red;
      &:hover {
        background-color: @blue;
        color: @white;
      }
      i.fa {
        line-height: 10px;
      }
    }
  }
}
.header-row{
  font-size: 0;
   >div{
    display: inline-block;
    font-size: 13px;
    float: none;
    vertical-align: bottom;
    color: #333333;
    font-weight: 700;
    &.small{
      font-size: 11px;
      line-height: 24px;
      text-align: center;
    }
  };
}
.orange-bottom{
  border-bottom: 2px solid @orange;
}
.table-header{
  font-family: "Roboto-bold", "Roboto Bold", "Roboto";
  font-weight: 700;
}
.green-row{
  background-color: rgb(142, 197, 59);
  color: white;
  border-bottom: rgb(4, 4, 68) 1px solid;
}
.black-row{
  background-color: rgb(4, 4, 68);
  color: white;
  border-bottom: white 1px solid;
}
.orange-row{
  background-color: rgb(255, 139, 56);
  color: white;
  border-bottom: rgb(4, 4, 68) 1px solid;
}
.trade-list{
  height: 355px;
  overflow-y: auto;
  .trade-item{
    background-color: white;
    cursor: pointer;
    font-size: 13px;
    border-bottom: 1px solid #949494;
    height: 35px;
    padding-top: 10px;
    overflow-x: auto;
    .wrench{
      color: black;
      &.primary{
        color: @orange;
      }
    }
    &.selected{
      color: white;
      background-color: #333333;
      padding-left: 1rem;
    }
  }
}
.step-three-scroll-note {
  color: red;
  font-size: 16px;
}
.scrollable-table{
  overflow: auto;
  width: 100%;
  white-space: nowrap;
}
.additional-horizontal-scrollbar-parent {
  overflow: auto;
  width: 100%;
  white-space: nowrap;
}
.additional-horizontal-scrollbar {
  height: 10px;
}
.state-cell{
  text-align: center;
  margin: 0;
  border-bottom: 1px solid #000;
  text-overflow: ellipsis;
  padding: 5px;
  &.state-header{
    color:@white;
    background-color: @orange;
    border-bottom: 0 none;
  }
  i.fa {
    display: block;
    margin: 3px 0 0;
    font-size: 45px;
  }
  input {
    color: #333333;
  }
  &.req{
    label {
      color: @green;
      margin: 0 0 5px;
      font-size: 14px;
      line-height: 16px;
    }
    &.invalid{
      label {
        color: red;
      }
      color: red;
    }
  }
  &.left-column{
    background-color: @blue;
    color: @white;
    border-bottom: 0 none;
    padding: 40px 0;
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.state-column{
  width: 272px;
  .state-row{
    height: 30px;
    &.header-row{
      background-color: @orange;
    }
    &.state-cell{
      background-color: black;
    }
    &.required-cell{
      color: red;
      &.valid{
        color: green;
      }
    }
  }
}
.table-for-ten{
  height: 350px;
}
.row{
  &.table-row{
    border-bottom: 1px solid #333333;
    height: 35px;
    margin:0 -10px 0 -10px;
  }
}
.table-el{
  color: #333333;
  font-family: 'Roboto-Regular', 'Roboto';
  font-size: 13px;
  padding-top: 7px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.checkbox-item{
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  color: #333333;
  vertical-align: middle;
}
.right{
  float: right;
  margin-right: 35px;
}
.a-right{
  text-align: right;
}
.right-icon{
  float: right;
  margin-right: 15px;
}
.bold{
  font-weight: 700;
}
.checklist{
    font-family: 'ArialMT', 'Arial';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: #333333;
}

.crop-thumbnail{
  overflow: hidden;
  height: 100px;
  width: 75px;
  margin: 25px auto 25px auto;
}
.crop-img{
  height: 100px;
  width: 75px;
}
.center-med-icon{
  font-size: 75px;
  text-align: center;
}
.centered{
  text-align: center;
}
.centered-div{
  margin: 0 auto;
  width: 50%;
}
.med-header{
  font-size: 23px;
  font-weight: 700;
  font-family: 'Rubik';
  margin: 20px 0 20px 0;
}
.lg-header{
  font-size: 23px;
  font-weight: 400;
  font-family: "Roboto-Regular", "Roboto";
}
.large-accordion-header {
  font-size: 22px;
  font-weight: bold;
}
.dropdown-header{
  font-weight: 700;
  margin: 20px 0 20px 0;
  text-align: center;
  font-size: 13px;
  color: #333333;
  font-family: 'Arial-BoldMT', 'Arial Bold', 'Arial';
}

.text-btn{
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
  &.med{
    font-size: 18px;
  }
  &.small{
    font-size: 11px;
  }
  &.active{
    color: @orange;
  }
}
.text-btn:hover{
  color: @orange;
}
.text-btn-blue{
  font-size: 10px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
  color: #01549B;
  &.large {
    font-size: 14px;
  }
}
.orange-info{
  color: #FF8B38;
  text-align: right;
  font-family: 'Rubik', 'Rubik';
  font-size: 14px;
}

ul.steps {
  padding: 0;
  margin-top: 50px;
  margin-bottom: 20px;

  li {
    display: inline-block;
    text-align: center;
    width: 15.12%;
    margin-left: 20px;
    padding: 30px 0;
    position: relative;

    &.active h2 {
      font-weight: bold;
    }
  }

  li:first-child {
    margin-left: 0;
  }

}
.seperator-container {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  float: left;
  &.seperator {
    border-bottom: #999 1px solid;
    outline: none;
    border-top: 0 none;
    border-left: 0 none;
    border-right: 0 none;
  }
}

.wide-input{
  width: 100%;
  height: 35px;
  padding: 0 5px;
  outline: none;
  border-radius: 0;
  border: 1px solid #d0d0d0;
  -webkit-transition: box-shadow 0.15s ease-in-out;
  transition: box-shadow 0.15s ease-in-out;
  &:hover {
    box-shadow: 0 0 3px 1px @orange inset;
  }
  &:active {
    box-shadow: 0 0 3px 1px @orange inset;
  }
  &:focus {
    box-shadow: 0 0 3px 1px @orange inset;
  }
  &:disabled {
    background-color: rgb(235,235,228);
    opacity: 0.65;
  }
}
textarea.wide-input {
  height: auto;
}
.preview-box{
  font-size: 120px;
  text-align: center;
  line-height: 150px;
}
.upload-label{
  text-align: center;
}
.float-clear-right{
  float: right;
  clear: both;
}
.align-side{
  padding-top: 40px;
}
.in-block{
  display: inline-block;
}
.table-cell {
  display: table-cell;
  min-width: 190px;
}
.btn-default{
  color: #000000 !important;
}
.btn.btn-orange.wide-short {
  max-width: 175px;
  max-height: 40px;
}
.align-button-right {
  text-align: right;
}

.step0 {
  padding: 0px 30px;
  label {
    font-size: 20px;
    margin-left: 20px;
    font-weight: 400;

    strong {
      display: inline-block;
      width: 230px;
    }
  }
  .btn.btn-orange, .btn.btn-black {
    max-width: 350px;
  }
  label[for] {
    margin: 0;
    width: 30px;
    position: relative;
    top: 7px;
  }

  input[type="checkbox"] {
    display: none;
  }

  .imageCheckbox ~ label .off,
  .imageCheckbox:checked ~ label .on {
    display: block;
  }
  .imageCheckbox ~ label .on,
  .imageCheckbox:checked ~ label .off{
    display: none;
  }

  &>div {
    padding: 5px 0;
  }
}
.col-width-20-percent {
  float: left;
  width: 20%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-width-30-percent {
  float: left;
  width: 30%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.faux-modal {
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.3);
  padding: 20px;
  margin-top: 100px;
}

i.fa-trash, i.fa-download {
  cursor: pointer;
}
.pass-reset {
  font-size: 20px;
}
.red-font {
  color: @red;
  font-size: 20px;
  cursor: pointer;
}
.site-modal {
  .ui-select-placeholder {
    line-height: 14px;
    padding-left: 5px;
    font-size: 14px;
  }
}
.ui-select-multiple.ui-select-bootstrap {
  padding: 3px 5px 0;
  min-height: 35px;
  border-radius: 0;
  box-shadow: 0 0 0;
  border: 1px solid #d0d0d0;
  .ui-select-choices-row {
    &.active > span {
      background-color: @orange;
      color: @white;
    }
    span {
      &:hover {
        background-color: @orange;
        color: @white;
      }
      &:focus {
        color: @white;
        background-color: @orange;
      }
    }
  }
  .ui-select-match-item.btn.btn-xs {
    color: @white !important;
    background-color: @blue;
    border: 0 none transparent;
    padding: 5px;
    font-size: 14px;
    border-radius: 0 0 0 0;
    .close {
      font-size: 24px;
      line-height: 20px;
      color: white;
      opacity: 1;
      font-weight: normal;
      text-shadow: 0 0 0 transparent;
      margin-left: 10px;
    }
    &:hover {
      background-color: @orange;
    }
  }
}
// dashboard components - shared styles
.dashboard-widget {
  .panel {
    padding: 15px;
    margin: 15px 0 !important;
    min-height: 387px;
  }
  h4 {
    font-weight: 700;
    font-size: 19px;
    text-align: center;
    i {
      margin-right: 15px;
      vertical-align: middle;
    }
  }
  hr {
    margin: 10px 0;
    border-color: #B0B0B0;
  }
  .orange-text { color: @orange; }
  .blue-text { color: @blue; }
  .green-text { color: @green; }
}

.dashboard-widget-api-errors {
  .panel {
    padding: 15px;
    margin: 15px 0 !important;
    min-height: 220px;
  }
  h4 {
    font-weight: 700;
    font-size: 19px;
    text-align: center;
    i {
      margin-right: 15px;
      vertical-align: middle;
    }
  }
  hr {
    margin: 10px 0;
    border-color: #B0B0B0;
  }
  .orange-text { color: @orange; }
  .blue-text { color: @blue; }
  .green-text { color: @green; }
}

.vendor-customer-service, .vendor-professionalism {
  h5 {
    font-weight: 700;
    margin: 10px 0 0;
  }
  hr.seperator {
    margin: 0 0 10px;
  }
  .panel {
    padding: 10px;
    border: 1px solid #999999;
    border-radius: 0;
    box-shadow: 0 0 0 0;
    .rating, .not-applicable {
      text-align: center;
    }
    i.fa.fa-star, i.fa.fa-star-o {
      margin: 0;
      color: @orange;
      cursor: pointer;
    }
    .overall {
      i.fa.fa-star, i.fa.fa-star-o {
        cursor: default;
      }
    }
    .question {
      p {
        font-size: 13px;
        strong {
          vertical-align: middle;
        }
      }
    }
  }
}
// specific widget styles
.vendor-review-metrics, .user-alerts, .preferred-vendors, .ap-vendors-exempt {
  ul {
    overflow-y: auto;
    padding: 10px;
    height: 304px;
    border: 1px solid #E0E0E0;
    margin: 0;
    list-style: none;
    li {
      font-weight: 600;
      font-size: 14px;
      line-height: 38px;
      cursor: pointer;
      color: @blue;
      position: relative;
      border-top: 1px solid #E0E0E0;
      transition: color 200ms ease-in-out;
      a {
        color: @blue;
        transition: color 200ms ease-in-out;
        display: block;
        &:hover {
          color: @orange;
          text-decoration: none;
        }
      }
      i {
        font-weight: normal;
        font-size: 24px;
        vertical-align: middle;
        margin-right: 5px;
      }
      .counter {
        font-weight: 600;
        background-color: @blue;
        color: #FFFFFF;
        width: 30px;
        text-align: center;
        line-height: 17px;
        height: 30px;
        border-radius: 20px;
        padding: 6px;
        vertical-align: unset;
        font-size: 12px;
        position: absolute;
        right: 0;
        top: 4px;
        transition: background-color 200ms ease-in-out;
      }
      &:hover {
        color: @orange;
        .counter {
          background-color: @orange;
        }
      }
      &:last-child {
        border-bottom: 1px solid #E0E0E0;
      }
    }
  }
}
.select-already-existing-agent {
  color: red;
  font-size: 16px;
}
.vendor-trade-categories {
  .panel {
    height: 805px;
    .chart-container {
      height: 721px;
      overflow-y: auto;
    }
    ul {
      min-height: 720px;
      height: 720px;
      overflow: auto;
    }
  }
}
.user-alerts {
  .panel {
    min-height: 545px;
    height: auto;
    i {
      color: @dark-red;
    }
    ul {
      height: 721px;
      overflow-y: auto;
      li {
        font-size: 12px;
        .alert-text {
          max-width: 83%;
          display: block;
          line-height: 20px;
          min-height: 38px;
          padding: 8px 0;
        }
      }
    }
  }
}
.vendor-review-metrics {
  .panel {
    ul {
      li {
        .review-text {
          max-width: 83%;
          display: block;
          line-height: 20px;
          min-height: 38px;
          padding: 8px 0;
        }
        .counter {
          top: 8px;
        }
      }
    }
  }
}
.account-history {
  .panel {
    ul {
      overflow-y: auto;
      padding: 10px;
      border: 1px solid #E0E0E0;
      margin: 0;
      list-style: none;
      li {
        font-size: 12px;
        border-bottom: 1px solid #E0E0E0;
        padding: 10px 0;
        &:last-child {
          border-bottom: 0 none;
        }
      }
    }
  }
}
.quicksource-proposals, .client-contract-widget {
  .row {
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    padding: 5px 0;
    .col-md-4, .col-md-3 {
      font-size: 24px;
      text-align: center;
      span {
        &.orange-text { color: @orange; }
        &.blue-text { color: @blue; }
        &.green-text { color: @green; }
        &.red-text { color: @dark-red; }
      }
    }
    .col-md-8, .col-md-9 {
      font-size: 13px;
      text-align: center;
      strong {
        display: block;
        padding: 5px 0;
      }
    }
    &:hover {
      cursor: pointer;
      background-color: @blue;
      color: #FFFFFF;
      strong {
        span {
          &.orange-text { color: #FFFFFF; }
          &.blue-text { color: #FFFFFF; }
          &.green-text { color: #FFFFFF; }
          &.red-text { color: #FFFFFF; }
        }
      }
    }
  }
}
// .quicksource-proposals {
//   .row {
//     .col-md-4 { font-size: 42px; }
//     .col-md-8 {
//       strong { padding: 17px 0; }
//     }
//   }
// }

.vendor-ratings {
  .nv-x text{
    font-size: 14px !important;
  }
}

#dashboard.vendor {
  .important-alert {
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    h3 {
      font-size: 18px;
      a {
        color: @dark-red;
        font-weight: bold;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
          color: @dark-red;
          cursor: pointer;
        }
      }
    }
  }
  .user-alerts, .account-history {
    .panel {
      height: 544px;
      ul {
        height: 458px;
        min-height: 458px;
      }
    }
  }
}
.office-location {
  .error-label {
    color: @red;
  }
  select.ng-touched.ng-invalid,
  select.ng-dirty.ng-invalid,
  input.ng-touched.ng-invalid,
  input.ng-dirty.ng-invalid {
    color: @red;
    border-color: @red;
    -webkit-transition: box-shadow 0.15s ease-in-out;
    transition: box-shadow 0.15s ease-in-out;
    box-shadow: 0 0 3px 1px @red inset;
  }
}
.modal-complianceSettings {
  .panel-heading {
    background-color: #e0e0e0;
    border-bottom: 1px solid #999;
    .panel-title {
      font-weight: 700;
      a {
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .panel-group .panel {
    margin-top: 0;
    margin-bottom: 0;
    border: 0 solid transparent;
  }
}
.modal-complianceType .modal-dialog .modal-content {
  min-height: 175px;
  max-height: 325px;
  padding: 10px;
  width: 475px;
  right: 0;
  left: 0;
  top: 30px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  div {
    padding-top: 10px;
  }
  h3 {
    text-align: center;
  }
}
.modal-companyComplianceTypesList .modal-dialog .modal-content {
  min-height: 350px;
  max-height: 625px;
  padding: 10px;
  width: 850px;
  right: 0;
  left: 0;
  top: 30px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  //overflow: scroll !important;
}
.modal-confirmVendorInformation .modal-dialog .modal-content {
  min-height: 350px;
  max-height: 925px;
  padding: 10px;
  width: 900px;
  right: 0;
  left: 0;
  top: 10px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  p {
    display: inline;
    font-weight: 300;
  }
  .content-padding {
    padding-left: 30px;
    padding-right: 30px;
  }
  .side-padding {
    padding-left: 20px;
    padding-right: 20px;
  }
  .row.row-padding {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .row-font {
    font-size: 18px;
  }
}
.modal-addEditCompanyComplianceType .modal-dialog .modal-content {
  min-height: 175px;
  max-height: 325px;
  padding: 10px;
  width: 425px;
  right: 0;
  left: 0;
  top: 30px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  div {
    padding-top: 10px;
  }
}
.modal-apConfig .modal-dialog .modal-content {
  height: 750px;
  padding: 10px;
  width: 1025px;
  right: 0;
  left: 0;
  top: 20px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  overflow-y: scroll;
  label {
    width: 100%;
  }
}
.modal-sosInGoodStanding .modal-dialog .modal-content {
  min-height: 150px;
  max-height: 275px;
  padding: 10px;
  width: 650px;
  right: 0;
  left: 0;
  top: 20px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;

  .label {
    font-size: 16px;
  }
}
.modal-addTrade, .modal-addTradeCategory {
  height: 350px;
  padding: 10px;
  width: 525px;
  right: 0;
  left: 0;
  top: 30px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
}
.modal-addTradeRequirements {
  height: 400px;
  padding: 10px;
  width: 1000px;
  right: 0;
  left: 0;
  top: 30px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
}
.modal-addTradeSpecialtyCategories {
  height: 450px;
  padding: 10px;
  width: 525px;
  right: 0;
  left: 0;
  top: 30px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
}
.modal-adjustPackagePricing {
  height: auto;
  top: 100px;
  width: 1000px;
  position: fixed;
  transform: translate(-50%);
  left: 50%;
}
.modal-addEditNote {
  max-height: 825px;
  overflow-y: auto;
  height: auto;
  top: 25px;
  width: 725px;
  position: fixed;
  transform: translate(-50%);
  left: 50%;
}
.modal-reviewStatus {
  padding: 10px;
  height: auto;
  width: 500px;
  top: 5%;
  position: fixed;
  transform: translate(-50%);
  left: 50%;
}
.modal-confirmModal {
  position: fixed;
  height: auto;
  top: 100px;
  width: 550px;
  transform: translate(-50%);
  left: 50%;
  padding: 10px;
}
.modal-countryModal {
  position: fixed;
  height: auto;
  top: 100px;
  width: 550px;
  transform: translate(-50%);
  left: 50%;
  padding: 10px;
}
.modal-changeVIVEStatus {
  top: 15px;
  width: 550px;
  position: fixed;
  transform: translate(-50%);
  left: 50%;
  .change-vive-status-element {
    height: 6vh;
  }
  .change-vive-status-element-paid {
    padding-top: 5px;
    height: 2vh;
  }
  .change-vive-status-element-note {
    height: 17vh;
  }
}
.modal-contactDetail {
  height: auto;
  top: 50px;
  padding: 15px;
  position: fixed;
  transform: translate(-50%);
  left: 50%;
}
.modal-confirmDelete {
  width: 400px;
  height: 170px;
  padding: 15px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  left: 0;
  top: 60px;
}
.modal-information {
  position: fixed;
  height: auto;
  top: 100px;
  width: 550px;
  transform: translate(-50%);
  left: 50%;
  padding: 10px;
}
.modal-feeVoucher {
  position: fixed;
  height: auto;
  top: 100px;
  width: 400px;
  transform: translate(-50%);
  left: 50%;
  padding: 10px;
}

.modal-allowbackgroundscrolling {
  overflow-y: auto !important;
}

.notes-grid-inline-title-and-checkbox {
  padding-top: 100px;
}
.notes-grid-inline-title-and-checkbox {
  * {
    display: inline-block;
  }
  .hide-notes-label {
    float: right;
    padding-top: 50px;
  }
}
.canada-business-lookup-link {
  padding-bottom: 10px;
  font-size: 16px;
}

// Implementations CSS
.implementation-form-title-div {
  display: inline-block;
  width: 100%;
}

.implementation-accordion {
  .panel-body {
    padding: 0px !important;
  }

  .implementation-button-div {
    padding-right: 20px;
    .upload-document-button {
      background: none !important;
      .upload-document-icon {
        font-size: 25px;
        color: blue;
      }
    }
    .view-document-button {
      background: none !important;
      .view-document-icon {
        font-size: 25px;
        color: black;
      }
    }
  }
}

.modal-selectVendorClient .modal-content {
  height: 275px;
  padding: 10px;
  width: 525px;
  right: 0;
  left: 0;
  top: 30px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
}

.ticket-content .modal-dialog .modal-content {
  height: 675px;
  padding: 10px;
  width: 950px;
  right: 0;
  left: 0;
  top: 30px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  overflow: scroll !important;
}

.modal-addNoteAndConfirm .modal-content {
  min-height: 350px;
  max-height: 400px;
  padding: 10px;
  width: 700px;
  right: 0;
  left: 0;
  top: 20px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
}

.modal-addressVerification .modal-content {
  min-height: 150px;
  max-height: 350px;
  padding: 10px;
  width: 725px;
  right: 0;
  left: 0;
  top: 20px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
}

.modal-addEditExemptVendorType .modal-content {
  min-height: 250px;
  max-height: 300px;
  padding: 10px;
  width: 650px;
  right: 0;
  left: 0;
  top: 30px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
}

.modal-setDateRange .modal-content {
  height: 225px;
  padding: 10px;
  width: 600px;
  right: 0;
  left: 0;
  top: 20px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
}

.modal-overrideBusinessVerification .modal-content {
  height: 675px;
  padding: 10px;
  width: 1075px;
  right: 0;
  left: 0;
  top: 20px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  overflow: scroll !important;

  .vendor-compliance-style {
    width: 1050px;

    .compliance-container {
      margin-left: 25px;
    }
    .compliance-text {
      text-align: left;
      margin: 0 !important;
      padding: 8px 0;
    }
    .compliance-row {
      & > div {
        margin-bottom: 10px;
        select, input {
          margin: 5px 0;
        }
      }
      .compliance-pass-fail {
        text-align: left;
        i.fa {
          padding: 11px 0;
        }
      }
      .row-border-bottom {
        border-bottom: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        margin: 0 2%;
        max-width: 96%;
        p {
          margin: 10px 0;
        }
      }
      .row-header {
        background-color: @blue;
        padding-top: 1%;
        padding-bottom: 1%;
        max-width: 96%;
        margin: 0 2%;
        label {
          color: #FFF;
        }
      }
    }
    .vendorCompliance.admin {
      margin-top: 15px;
      label {
        display: block;
        text-align: center;
      }
      .row {
        margin-top: 15px;
        margin-bottom: 15px;
        p {
          text-align: center;
        }
      }
    }
  }
}

.modal-clientSelection, .modal-esignTemplate {
  .modal-dialog {
    width: 600px !important;
  }

  .modal-content {
    padding: 20px;
    right: 0;
    left: 0;
    position: fixed;
    margin-left: auto;
    margin-right: auto;

    .modal-body-content {
      text-align: center;
    }

    button {
      width: 100%;
    }

    .row-padding {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.row.row-padding {
  padding-top: 10px;
  padding-bottom: 10px;
}

.addEditClientRegisteredVendor {
  padding: 10px;
  //position: fixed;
  margin-left: auto;
  margin-right: auto;
  overflow: scroll !important;

  h3 {
    margin-bottom: 0px;
  }

  input {
    width: 100%;
  }

  select {
    width: 100%;
    height: 26px;
  }

  .row div {
    margin: 4px 0px;
  }

  .required-a {
    float: right;
    color: #ff0000;
    font-size: 14px;
  }

  .error-label{
    color: red;
  }

  .error-input{
    border: 1px solid red;
  }
}

.modal-addEditExemptOrClientRegisteredVendor .modal-content {
  height: 310px;
  padding: 10px;
  width: 520px;
  right: 0;
  left: 0;
  top: 20px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  overflow: scroll !important;
}

.modal-addEditClientRegisteredVendor .modal-content {
  //height: 750px;
  height: 95vh;
  padding: 10px;
  width: 85%;
  right: 0;
  left: 0;
  top: 0;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  overflow: scroll !important;

  h3 {
    margin-bottom: 0px;
  }

  input {
    width: 100%;
  }

  select {
    width: 100%;
    height: 26px;
  }

  .row div {
    margin: 4px 0px;
  }

  .required-a {
    float: right;
    color: #ff0000;
    font-size: 14px;
  }

  .error-label{
    color: red;
  }

  .error-input{
    border: 1px solid red;
  }
}

.modal-editClientUploadedUser .modal-content  {
  height: auto;
  padding: 10px;
  width: 100%;
  max-width: 700px;
  right: 0;
  left: 0;
  top: 0;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  overflow-x: scroll !important;

  h3 {
    margin-bottom: 0px;
  }

  input {
    width: 100%;
  }

  select {
    width: 100%;
    height: 26px;
  }

  .row div {
    margin: 4px 0px;
  }

  .required-a {
    float: right;
    color: #ff0000;
    font-size: 14px;
  }
}

.opacity20Percent {
  opacity: .2;
}

.modal-addEditExemptVendorStepTwo .modal-content {
  height: 750px;
  padding: 10px;
  width: 1025px;
  right: 0;
  left: 0;
  top: 30px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  overflow: scroll !important;

  input {
    width: 100%;
  }

  select {
    width: 100%;
    height: 26px;
  }

  .row div {
    margin: 4px 0px;
  }

  .required-a {
    float: right;
    color: #ff0000;
    font-size: 14px;
  }

  .error-label{
    color: red;
  }

  .error-input{
    border: 1px solid red;
  }
}

.modal-addEditExemptVendorStepOne .modal-content,
.modal-chooseExemptVendorTypeModal .modal-content {
  min-height: 175px;
  max-height: 400px;
  padding: 10px;
  width: 645px;
  right: 0;
  left: 0;
  top: 30px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
}

.exemptVendorInfo {
  margin-left: auto;
  margin-right: auto;
  overflow: scroll !important;

  input {
    width: 100%;
  }

  select {
    width: 100%;
    height: 26px;
  }

  .row div {
    margin: 4px 0px;
  }

  .required-a {
    float: right;
    color: #ff0000;
    font-size: 14px;
  }

  .error-label{
    color: red;
  }

  .error-input{
    border: 1px solid red;
  }
}

.vendor-my-company {
  .row {
    .form-group {
      .error-label{
        color: red;
      }

      .error-input{
        border: 1px solid red;
      }
    }
  }
}

.modal-addEditInsurancePolicySites .modal-content {
  width: 70vw;
  padding: 10px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  left: 0;
  top: 10px;
}

.modal-uploadExemptVendor .modal-content, .modal-uploadClientRegisteredVendor .modal-content, .modal-uploadClientUsers .modal-content {
  min-height: 675px;
  max-height: 1000px;
  padding: 10px;
  width: 1050px;
  right: 0;
  left: 0;
  top: 30px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
}

.modal-verifyBusinessChecks .modal-content {
  min-height: 175px;
  padding: 10px;
  width: 720px;
  right: 0;
  left: 0;
  top: 20px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
}
.modal-generic .modal-content {
  min-height: 175px;
  padding: 15px;
  width: 720px;
  right: 0;
  left: 0;
  top: 20px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  a {
  font-weight: bold;
  color: #041E39;
  text-decoration: underline;
    &:hover {
      color: #ffa500;
    }
  }
}
.contact-missing-email {
  color: #ff0000;
}

::-webkit-scrollbar-track:horizontal {
  border-top: 1px solid #E7E7E7;
  box-shadow: 0 1px 1px 0 #F6F6F6 inset, 0 -1px 1px 0 #F6F6F6 inset;
}

::-webkit-scrollbar-track:vertical {
  border-top: 1px solid #E7E7E7;
  box-shadow: 0 1px 1px 0 #F6F6F6 inset, 0 -1px 1px 0 #F6F6F6 inset;
}

::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: #C1C1C1;
  border-color: transparent;
  border-radius: 9px 8px 8px 9px;
  border-style: solid;
  border-width: 3px 3px 3px 4px; /* Workaround because margins aren't supported */
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  background-color: #FAFAFA;
  width: 16px;
}

.view-implementation-document-modal .modal-dialog .modal-content {
  position: fixed;
  height: auto;
  top: 5%;
  width: 65vw;
  transform: translate(-50%);
  left: 50%;
  padding: 5px;

  .implementation-document-grid-container {
    .ui-grid {
      height: 45vh !important;
    }
  }
}

.view-implementation-note-modal .modal-dialog .modal-content {
  position: fixed;
  height: auto;
  top: 5%;
  width: 50vw;
  transform: translate(-50%);
  left: 50%;
  padding: 5px;
}

.expired-date-color {
  color: red;
}

.implementation-span-company-header {
  width: 100%;
  h3 {
    display: inline-block;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .inner-span {
    float: right;
  }
  a {
    padding: 0 15px;
    font-size: 18px;
  }
}

.implementation-table {
  width: 100%;
  height: 100%;
  .implementation-table-header-row {
    th {
      background-color: #E2E2E2;
      font-family: "Roboto", "Rubik", sans-serif;
      border: 1px solid gray;

      font-size: 18px;
      text-align: center;
      padding: 10px 0;

      &.implementation-table-first-cell {
        border-left: none !important;
      }
      &.implementation-table-last-cell {
        border-right: none !important;
      }
    }
  }
  .implementation-table-cell-row {
    .implementation-table-subcategory-name {
      font-size: 16px;
      padding-left: 25px;
    }
    td {
      border-left: 1px solid gray;
      border-right: 1px solid gray;

      text-align: center;
      border-bottom: 1px solid gray;
      padding: 5px 0;
      background-color: #F4F4F4;
      width: auto;
      &.implementation-table-datepicker-cell, &.implementation-table-vendorcount-cell {
        padding: 0 15px;

        ::placeholder {
          text-align: center;
          color:blue;
        }
        .expired-date-color-change {
          color: red;
        }
        .implementation-table-date-input {
          text-align: center;
        }
      }
      &.implementation-table-comments-cell {
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px !important;
        padding-bottom: 2px !important;
        textarea {
          font-size: 12px;
          line-height: normal;
          width: 100%;
        }
      }
      &.implementation-table-text-cell {
        padding: 5px;

        .text-cell-input-group {
          width: 100% !important;
          input {
            font-size: 14px;
            width: 100%;
          }
        }
      }
      &.implementation-table-combobox-cell {
        padding: 5px;
        .combobox-cell-input-group {
          width: 100% !important;
          select {
            width: 100%;
          }
        }
      }
      &.implementation-table-first-cell {
        width: 20% !important;
        border-left: none !important;
      }
      &.implementation-table-last-cell {
        width: 20% !important;
        border-right: none !important;
      }
    }
  }
}
.implementation-comments-div {
  textarea {
    width: 100%;
    font-size: 16px;
  }
}

.implementation-date-expiration {
  font-weight: bold;
  color: darkred;
}

.implementation-accordion-date-expiration {
  font-weight: bold;
  color: red;
}

.implementation-accordion-header-date {
  position: absolute;
  left: 450px;
  padding-top: 5px;
}

.modal-unlinkApVendorModal .modal-content {
  height: 650px;
  padding: 10px;
  width: 950px;
  right: 0;
  left: 0;
  top: 20px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
}

.modal-addUpdateCompanyImplementation {
  width: 575px;
  height: 500px;
  padding: 15px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  left: 0;
  top: 50px;
}
.modal-addUpdateImplementationCategory {
  width: 475px;
  height: 325px;
  padding: 10px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  left: 0;
  top: 50px;
}
.modal-addUpdateImplementationSubCategory {
  width: 475px;
  height: 450px;
  padding: 10px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  left: 0;
  top: 50px;
}
.modal-viewImage .modal-content {
  width: 1050px;
  height: 820px;
  padding: 10px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  left: 0;
  top: 10px;
  overflow: scroll !important;
}
.notes-grid-inline-title-and-checkbox {
  padding-top: 100px;
}
.notes-grid-inline-title-and-checkbox {
  * {
    display: inline-block;
  }
  .hide-notes-label {
    float: right;
    padding-top: 50px;
  }
}
.canada-business-lookup-link {
  padding-bottom: 10px;
  font-size: 16px;
  display: block;
}
.add-padding-top-grid-spacer {
  padding-top: 50px;
}
.add-insurance-agent-modal {
  position: fixed;
  height: auto;
  top: 5%;
  width: 65vw;
  transform: translate(-50%);
  left: 50%;
  padding: 5px;
}
.multiple-email-address-note {
  color: red;
  font-size: 14px;
  padding-left: 15px;
}

.admin-client-vendor-statuses-chart svg {
  margin: auto;
}

.signing-iframe {
  height: 85vh;
  width: 100%;
}

.success-green {
  color: @vive-green;
}

.color-red {
  color: #ff0000;
}

// Hack to allow selection of both checkbox and grid row (both select grid row when clicked)
.ui-grid-cell.ui-grid-disable-selection.ui-grid-row-header-cell {
  pointer-events: auto;
}
// NEw Client Vendor Details View
@import "./clientVendorDetails.less";
// New Vendor Renewal and Review and Pay
@import "./vendorRenewal.less";
@import "./vendorReviewAndPay.less";
// New Trades & Licenses
@import "./tradesLicenses.less";
// QuickSource Bidding
@import "./quickSource.less";
// New Insurance
@import "./coi.less";
// Add / Edit Sites
@import "./addEditSites.less";
// ADMIN: Edit Vendor Info
@import "./adminEditVendor.less";
// New File Component Styles
@import "./files.less";
// Custom css for leaflet map in sites
@import "./leafletMap.less";

// Public Download Link Styles
.public-downloads {
  a {
    color: @blue;
    text-decoration: underline;
    font-weight: bold;
    &:hover {
      color: @orange;
    }
    span {
      display: inline-block;
      text-decoration: underline;
    }
  }
}

// Global Chart styles
.nvd3 text {
  font-family: "Rubik", "Rubik", Helvetica, Arial, sans-serif !important;
  font-size: 11px !important;
}
.nvd3 .title {
  font-family: "Rubik", "Rubik", Helvetica, Arial, sans-serif !important;
  font-size: 11px !important;
}
.nvtooltip {
  font-family: "Rubik", "Rubik", Helvetica, Arial, sans-serif !important;
  font-size: 11px !important;
}
.chart-widget {
  .chart-container {
    border: 1px solid #E0E0E0;
    height: 304px;
    overflow-y: auto;
    .nvd3.multiChart .axis .nv-axis line {
      stroke: none;
      fill: none;
      }
  }
  .nv-zeroLine {
    opacity: 0 !important;
  }
  .tick {
    line {
      opacity: 0 !important;
    }
  }
}
.videos{
  // VIDEO DROPDOWNS
  .dropdown-menu {
    width: 375px;
    margin: 0;
    padding: 0;
    li {
      cursor: pointer;
      text-transform: uppercase;
      background-color: #041E39;
      color: #FFFFFF;
      padding: 10px 10px;
      -webkit-transition: all 0.15s ease-in-out;
      transition: all 0.15s ease-in-out;
      &:hover {
        background-color: @orange;
      }
    }
  }
}
// OVERLAY
.vive-overlay {
  position: fixed;
  width: 100%;
  text-align: center;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 9999;
  background: rgba(215, 215, 215, 0.85)
}
#progressOverlay {
  padding: 40vh 0px;
  .progress {
    max-width: 40%;
    margin: 35px auto 0;
    height: 50px;
    border-radius: 0;
    background-color: #b3b3b3;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    .progress-bar {
      font-size: 16px;
      line-height: 50px;
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
      &.progress-bar-success {
        background-color: #041E39;
      }
      b {
        font-weight: normal;
        letter-spacing: 1px;
      }
    }
  }
}
#loading-bar {
  .bar {
    position: relative;
    background: @vive-green;
  }
}
#loading-bar-spinner{
  .spinner-icon {
    border-top-color: @vive-green;
    border-left-color: @vive-green;
  }
}
// CSS ANIMATIONS
.animate-show {
  -moz-transition:all ease-in-out 0.5s;
  -o-transition:all ease-in-out 0.5s;
  transition:all ease-in-out 0.5s;
  display:block!important;
  -webkit-transition:all ease-in-out 0.5s;
  transition:all ease-in-out 0.5s;
  opacity:1;
}

.animate-show.ng-hide-add,
.animate-show.ng-hide-remove {
  display:block!important;
}

@keyframes spinner {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
};

.spinning-icon {
  animation: spinner 2s infinite linear;
  font-size: 70px;
};

.fs-24 {
  font-size: 24px;
}

.animate-show.ng-hide {
  opacity:0;
}
// BOOTSTRAP WIDTH OVERRIDE - USE WITH CAUTION
.container {
  @media (min-width: 768px) {
    width: 750px;
  }
  @media (min-width: 992px) {
    width: 970px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
  @media (min-width: 1396px) {
    width: 1366px;
  }
  @media (min-width: 1552px) {
    width: 1522px;
  }
}
.print-show {
  display: none;
}
.print-container {
  .non-print-buttons {
    display: none;
  }
}
@media print {
  .print-show {
    display: block;
  }
  .vive-logo {
    height: 67pt;
    width: 65pt;
    display: block;
  }
  .print-container {
    .btn {
      display: none;
    }
  }
  h4  {
    font-weight: bold;
  }

  // class attached to modal body
  .modal-open {
    overflow: visible !important;
    visibility: hidden !important;

    .ticket-content {
      overflow: visible !important;
      position: absolute !important;

      & ~ .ng-isolate-scope {
        .container, .admin-vendor-details {
          display: none !important;
        }
      }

      .hide-on-print {
        visibility: hidden !important;
      }

      .modal-backdrop {
        visibility: hidden !important;
      }

      .centered { text-align: center; }
      .col-md-1 { width: 8%; float: left; };
      .col-md-2 { width: 16%; float: left; };
      .col-md-3 { width: 25%; float: left; };
      .col-md-4 { width: 33%; float: left; };
      .col-md-5 { width: 41%; float: left; };
      .col-md-6 { width: 50%; float: left; };
      .col-md-7 { width: 58%; float: left; };
      .col-md-8 { width: 66%; float: left; };
      .col-md-9 { width: 75%; float: left; };
      .col-md-10 { width: 83%; float: left; };
      .col-md-11 { width: 91%; float: left; };
      .col-md-12 { width: 100%; float: left; };

      .print-content * {
        visibility: visible;
        overflow: visible;
      }

      .modal-dialog {
        display: block;
        visibility: visible !important;
        overflow: visible !important;
        position: absolute !important;

        .modal-content {
          border: hidden !important;
          max-width: 725px;
          height: auto;
          min-height: 675px;
          left: 0;
          top: 0;
          margin: 0 !important;
          padding: 0 !important;
          display: block;
          visibility: visible !important;
          overflow: visible !important;
        }
      }
    }
  }
}
