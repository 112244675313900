.edit-vendor {
  .form {
    .required-a {
      float: none;
      margin-right: 0;
      font-weight: 500;
      line-height: 14px;
    }
    .ui-select-bootstrap > .ui-select-match > .btn, .ui-select-bootstrap > input.ui-select-search {
      display: block;
      margin: 0;
      font-weight: normal;
      text-align: center;
      cursor: pointer;
      background-image: none;
      border: 1px solid #d0d0d0;
      white-space: nowrap;
      padding: 10px 0;
      font-size: inherit;
      line-height: 0;
      border-radius: 0;
      -webkit-transition: box-shadow 0.15s ease-in-out;
      transition: box-shadow 0.15s ease-in-out;
      min-height: 36px;
      box-shadow: 0 0 0 0;
      &:hover {
        box-shadow: 0 0 3px 1px @orange inset;
        background-color: #FFFFFF;
      }
      &:active {
        box-shadow: 0 0 3px 1px @orange inset;
      }
      &:focus {
        box-shadow: 0 0 3px 1px @orange inset;
      }
      .ui-select-match-text {
        line-height: 1;
        span {
          text-align: center;
        }
      }
    }
    .error-label {
      color: @red;
    }
    select.ng-touched.ng-invalid,
    select.ng-dirty.ng-invalid,
    input.ng-touched.ng-invalid,
    input.ng-dirty.ng-invalid {
      color: @red;
      border-color: @red;
      -webkit-transition: box-shadow 0.15s ease-in-out;
      transition: box-shadow 0.15s ease-in-out;
      box-shadow: 0 0 3px 1px @red inset;
    }
    span.required {
      color: @red;
      font-size: inherit;
      padding-left: 0;
      text-align: inherit;
    }
  }
}
