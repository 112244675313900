@import (reference) "../../node_modules/bootstrap/less/bootstrap.less";
@import (reference) "./colors.less";

.breadcrumbs {
  font-size: 20px;
  margin-bottom: 2.25%;
  margin-top: 3%;
  a {
    color: black;
    text-decoration: none;
  }

  a:hover, i:hover {
    color: @orange;
  }

  hr.seperator {
    margin: 5px 0 5px 0;
  }

  .crumbs {
    margin: 0 0 0 10px;
    span > span {
      font-size: 16px;
    }
  }

  .fa-caret-right {
    color: @orange;
    padding-left: 5px;
    padding-right: 5px;
  }
}