@import "./dashboard/alert.less";

#dashboard {
  alert-center {
    margin-top: 10px;
    .col-md-4 {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .title {
    font-size: 23px;
    font-weight: 700;
    margin: 0 0 25px;
  }
  .panel {
    margin-top: 15px;
    border: 1px solid #E0E0E0;
    .panel-body {
      .dashboard-graph {
        border: 1px solid #E0E0E0;
        margin: 10px 0;
        padding-bottom: 10px;
        h5 {
          text-align: center;
          background-color: @blue;
          margin: 0 0 15px;
          padding: 8px 0;color: #FFFFFF;
          text-transform: uppercase;
        }
        .legend-container {
          text-align: center;
          ul {
            text-align: center;
            margin: 0;
            padding: 0;
            list-style: none;
            li {
              display: inline-block;
              margin-left: 10px;
              span.chart-legend {
                display: inline-block;
                padding: 3px 7px;
                margin-right: 5px;
                vertical-align: middle;
                max-width: 7%;
              }
              span.chart-label-legend-text {
                display: inline-block;
                max-width: 93%;
                vertical-align: middle;
                font-size: 9px;
              }
              &:first-child {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}
