@font-face {
  font-family: 'vive-icons';
  src: url('vive-icons.eot');
  src: url('vive-icons.eot#iefix') format('embedded-opentype'),
       url('vive-icons.woff2') format('woff2'),
       url('vive-icons.woff') format('woff'),
       url('vive-icons.ttf') format('truetype'),
       url('vive-icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="vive-"]:before, [class*=" vive-"]:before {
  font-family: "vive-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  font-size: 120%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.vive-license:before { content: '\e800'; } /* '' */
.vive-sign-agreement:before { content: '\e801'; } /* '' */
.vive-preferred-vendor:before { content: '\e802'; } /* '' */
.vive-review:before { content: '\e803'; } /* '' */
.vive-owner-info:before { content: '\e805'; } /* '' */
.vive-reconcile:before { content: '\e806'; } /* '' */
.vive-insurance:before { content: '\e807'; } /* '' */
.vive-company-info:before { content: '\e808'; } /* '' */
.vive-vendor-statuses:before { content: '\e809'; } /* '' */
.vive-quicksource:before { content: '\e80a'; } /* '' */
.vive-alerts:before { content: '\e80b'; } /* '' */
.vive-notifications:before { content: '\e80c'; } /* '' */
.vive-check:before { content: '\e804'; } /* '' */
.vive-contracts:before { content: '\e80d'; } /* '' */
.vive-preferred-vendors-5:before { content: '\e80e'; } /* '' */
.vive-preferred-vendors-4:before { content: '\e80f'; } /* '' */
.vive-preferred-vendors-3:before { content: '\e810'; } /* '' */
.vive-preferred-vendors-2:before { content: '\e811'; } /* '' */
.vive-preferred-vendors-1:before { content: '\e812'; } /* '' */
