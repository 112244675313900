@import './colors.less';
vive-table {
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
}

.table-filter {
  .search-placeholder {
    font-size: 20px;
    cursor: pointer;
  }

  .options-btn {
    .btn-orange;
    width: 30px;
    height:30px;
    padding: 5px;
    font-size: 20px;
    box-shadow: none;
    border-radius: 0;
  }
}

/* APP specific styling */
.table {
  border-collapse: collapse;
  // border-spacing: 1px;
  thead > tr > th {
    border-bottom: 2px solid @orange;
    // border-right: 1px white;
    text-align: center;
  }

  td {
    vertical-align: middle;
    input {
      width: 100%;
      box-sizing: border-box;
    }
    select {
      height: 100%;
    }
  }

  tbody > tr > td {
    border-top: none;
    border-bottom: 1px solid #ddd;
    vertical-align: middle;
    .fa-2x {
      font-size: 1.6em;
      margin: 0 3%;
    }
    &.loading-td {
      border: 1px solid #ddd; 
      height: 300px;
      text-align: center; 
      vertical-align: middle;
    }
  }
}

.table-pagination, .table-showcount {
  .btn-group {
    button {
      font-size: 12px;
      border-radius: 0;
      color: @white;
      background-color: @blue;
      border: 1px solid lightgray;
      &.active {
        color: white;
        background-color: @orange;
      }
      &:hover {
        color: white;
        background-color: @orange;
      }
    }
  }

  ul.pagination {
    text-transform: uppercase;
    margin: 0;
    li {
      a {
        color: @white;
        background-color: @blue;
        border: 1px solid lightgray;
        border-radius: 0;
        -moz-transition: all 0.15s ease-in-out;
        -webkit-transition: all 0.15s ease-in-out;
        transition: all 0.15s ease-in-out;
        &:hover {
          color: white;
          background-color: @orange;
        }
      }
    }
    li.active{
      a {
        color: white;
        border: 1px solid lightgray;
        background-color: @orange;
        &:hover {
          border: 1px solid lightgray;
        }
      }
    }
    li.disabled {
      a {
        background-color: lightgray;
        color: #c3c3c3;
        border: 1px solid lightgray;
        &:hover {
          background-color: lightgray;
          color: #c3c3c3;
          border: 1px solid lightgray;
        }
      }
    }
  }
}

.stacked-tables {
  &.no-thead {
    thead {
      display: none;
    }
  }
  table {
    border-spacing: 0;
    th {
      background: @orange;
      color: white;
      border: none;
    }
    tbody {
      td {
        vertical-align: middle !important;
        border-bottom: none;
        &:first-child {
          text-align: left !important;
        }
      }
    }
  }
}

.simple-tables {
  table {
    border-spacing: 0;
    th {
      background: #ff830f;
      color: white;
      border: none;
    }
    tbody {
      td {
        vertical-align: middle !important;
        border-bottom: none;
      }
    }
  }
}
// VENDOR OWNER TABLE SPECIFICS
.vendor-owner {
  h4.vendor-owner-header {
    font-size: 14px;
    font-weight: 500;
    color: @red;
    text-align: right;
    margin: 0 0 2%;
  }
}
// INSURANCE DOC LIST
.doc-list {
  .doc-item {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: #CCC;
    }
    td {
      padding: 1% 3%;
    }
  }
}