.coi {
  a {
    font-weight: bold;
    color: #041E39;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: @orange;
    }
  }
  label {
    margin-top: 5px;
    input[type="checkbox"] {
      height: 20px;
      width: 20px;
      vertical-align: bottom;
    }
  }
  .auto-liability,
  .general-liability,
  .professional-liability,
  .umbrella-liability,
  .workers-comp {
    h4 {
      margin: 10px 0;
    }
    h5 {
      margin-bottom: 30px;
    }
  }
  .file-item-container {
    border: 1px solid #999999;
    padding: 0;
    .file-item {
      list-style: none;
      margin: 0;
      font-size: 13px;
      border-bottom: 1px solid #999999;
      padding: 5px;
      display: flex;
      display: -webkit-flex;
      flex-wrap: wrap;
      &:last-child {
        border-bottom: none;
      }
      .col-md-1 {
        text-align: center;
        i {
          font-size: 16px;
          margin: 9px 0;
        }
      }
      label {
        margin: 8px 0;
      }
    }
  }
  .input-group-btn {
    .btn {
      border-radius: 0 !important;
      line-height: 1.5;
      &.btn-orange-datepicker {
        color: #FFFFFF;
        background-color: @orange;
        &:hover {
          background-color: @blue;
        }
      }
    }
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
  }
  .vertical-border {
    border-left: #999 1px solid;
  }
  .error-label {
    color: @red;
  }
  select.ng-touched.ng-invalid,
  select.ng-dirty.ng-invalid,
  input.ng-touched.ng-invalid,
  input.ng-dirty.ng-invalid {
    color: @red;
    border-color: @red;
    -webkit-transition: box-shadow 0.15s ease-in-out;
    transition: box-shadow 0.15s ease-in-out;
    box-shadow: 0 0 3px 1px @red inset;
  }
  span.required {
    color: @red;
    font-size: inherit;
    padding-left: 0;
    text-align: inherit;
  }
}
