.vendor-review-pay {
  // Contains all of the accordions and moves them to be centered on the page
  .center-block {
    max-width: 80%;
    .panel-group {
      .panel + .panel {
        margin-top: 0;
      }
      .panel {
        border-radius: 0;
        margin-bottom: 0;
        background-color: #FFFFFF;
        border: 0 none transparent;
        border-radius: 0;
        -webkit-box-shadow: 0 0 0 0 transparent;
        box-shadow: 0 0 0 0 transparent;
        &.panel-open {
          .panel-heading {
            background-color: #e0e0e0;
          }
          .panel-collapse {
            border-left: 1px solid #e0e0e0;
            border-right: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
          }
        }
        &:first-child {
          .panel-heading {
            border-top: 1px solid #e0e0e0;
          }
        }
        .panel-heading {
          -webkit-transition: background-color 200ms linear;
          -moz-transition: background-color 200ms linear;
          -o-transition: background-color 200ms linear;
          -ms-transition: background-color 200ms linear;
          transition: background-color 200ms linear;
          border-bottom: 1px solid #e0e0e0;
          border-collapse: collapse;
          &:hover {
            background-color: #e0e0e0;
          }
          h4 {
            &.panel-title {
              font-size: 22px;
              font-weight: normal;
              margin: 5px 0;
              a {
                display: block;
                text-decoration: none;
                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
        // TODO: Remove this to its own sheet when new trade license is implemented
        // Style overrides for trade and licensing
        .shadowbox {
          .trade-licensing-wizard {
            .btn.btn-orange-thin, .btn.btn-black-thin {
              display: block;
              width: 100%;
            }
            .btn {
              max-width: 450px;
              display: block;
              margin-top: 0;
              width: 100%;
            }
            .zipcode-box {
              border: 1px solid #E0E0E0;
              padding: 10px;
              max-width: 465px;
              box-shadow: 0px 0px 7px -3px rgba(0, 0, 0, 0.5);
            }
            ul.tradeList {
              margin: 0;
              padding: 0;
              list-style: none;
              li {
                display: inline-block;
                background-color: @orange;
                color: @white;
                padding: 5px 10px;
                margin: 3px 5px 3px 0px;
                .removeTrade {
                  font-size: 18px;
                  vertical-align: middle;
                  margin-left: 10px;
                  font-weight: bold;
                }
              }
            }
          }
          .ui-select-multiple.ui-select-bootstrap {
            padding: 3px 5px 0;
            min-height: 35px;
            border-radius: 0;
            box-shadow: 0 0 0;
            border: 1px solid #d0d0d0;
            .ui-select-choices-row {
              &.active > span {
                background-color: @orange;
                color: @white;
              }
              span {
                &:hover {
                  background-color: @orange;
                  color: @white;
                }
                &:focus {
                  color: @white;
                  background-color: @orange;
                }
              }
            }
            .ui-select-match-item.btn.btn-xs {
              color: @white !important;
              background-color: @blue;
              border: 0 none transparent;
              padding: 5px;
              font-size: 14px;
              border-radius: 0 0 0 0;
              .close {
                font-size: 24px;
                line-height: 20px;
                color: white;
                opacity: 1;
                font-weight: normal;
                text-shadow: 0 0 0 transparent;
                margin-left: 10px;
              }
              &:hover {
                background-color: @orange;
              }
            }
          }
          .tab-header-row {
            // border: 1px solid #999999;
            border-bottom: 0;
            .tab-header {
              border-bottom: 0;
              color: @white;
              background-color: @blue;
              padding: 8px 0;
              font-size: 12px;
              font-weight: normal;
              -webkit-transition: background-color 0.15s ease-in-out;
              transition: background-color 0.15s ease-in-out;
              &.active {
                background-color: @orange;
              }
              &:hover {
                background-color: @orange;
              }
            }
          }
          .tab-content-area {
            .panel.panel-default {
              box-shadow: 0 0 0 0;
              border-radius: 0;
              border-top: 0;
              border-color: #999999;
              border-collapse: collapse;
              padding-top: 10px;
              .panel-body {
                .ta-toolbar {
                  .btn-group {
                    margin: 5px 0;
                    button.btn, div.btn {
                      border-radius: 0;
                    }
                  }
                }
                .ta-scroll-window.form-control {
                  border: 1px solid #999999;
                  box-shadow: 0 0 0 0;
                  border-radius: 0;
                  margin-top: 10px;
                  div {
                    outline: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.pay-fees {
  .center-block {
    border: 1px solid #e0e0e0;
    max-width: 84%;
    margin-bottom: 25px;
    .receipt-details {
      max-width: 80%;
      margin: 25px auto;
      padding: 3%;
      border: 1px solid #5A6673;
      h2 {
        font-weight: 500;
        font-size: 30px;
      }
      h4 {
        font-weight: 600;
        font-size: 18px;
      }
    }
  }
}
