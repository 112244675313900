@import (reference) "./colors.less";

header {
  background: @blue;
  position: relative;
  padding: 5px;
  margin-bottom: 20px;
  .logo {
    float: left;
    margin-top: 10px;
    max-width: 300px;
    margin-bottom: 20px;
    img {
      width: 100px;
      float: left;
    }
    span {
    color: #FFFFFF;
    float: left;
    margin: 40px 0 0 20px;
    font-size: 15px;
    font-weight: 400;
    }
  }

  .user {
    padding: 10px;
    clear: right;
    color: white;
    font-size: 18px;
    text-transform: uppercase;
    a {
      color: white;
      font-size: 20px;
    }
  }

  nav {
    float: right;
    clear: right;

    ul {
      list-style: none;
      padding-left: 0px;
      padding-top: 20px;
      margin-top: 15px;

      li {
        padding: 0px 5px;
      }

      li.header-section {
        display: inline-block;
        text-align: center;
        vertical-align: top;
        padding: 0 10px;

        a {
          color: #fff;
          // font-size: 16px;
          text-transform: uppercase;
          cursor: pointer;
          display: block;
          &:hover {
            i, .header-text {
              color: @orange;
            }

            text-decoration: none;
          }
        }
      }
    }
  }
}

.header-section {
  display: inline-block;
  position: relative;

  &:hover {
    ul.dropdown-menu {
      display: block;
      margin-top: -2px;
    }
  }

  .fa {
    font-weight: 700;
  }

  .header-icon{
    font-size: 22px;
    line-height: 30px; 
    color: white;
  }
  .header-text{
    color: white;
    font-size: 14px; 
    font-weight: 700;
  }

  .dropdown-menu {
    background-color: @orange;
  }
}
