@import (reference) "./../colors.less";

#dashboard .alert-center-alert {
  > .panel {
    margin: 5px 0;
    border: 1px solid @red; 
    box-shadow: 0 0 0 0 transparent inset;
    border-radius: 0;
    -webkit-transition: box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out;
    > .panel-body {
      padding: 5px 15px;
      a {
        text-decoration: none;
        &:hover, &:visited {
          text-decoration: none;
        }
      }
      .button-styles {
        display: block;
        text-align: center;
        max-width: 40%;
        width: 100%;
        height: auto;
        margin: 0 auto;
        position: relative;
        line-height: 0;
        border-radius: 0;
        &.orange-btn {
          padding: 4% 0;
          margin: 0 15% 0 0;
        }
      }
      button {
        display: block;
        text-align: center;
        margin: 0 auto;
        &.close-button {
          z-index: 20;
          margin: 0;
          font-size: 14px;
          padding: 0;
          background-color: transparent;
          color: @blue;
          &:hover {
            color: @orange;
          }
        }
      }
      .alert-text {
        .fa-exclamation {
          color: @red;
          font-size: 18px;
          margin-right: 10px;
          display: inline-block;
        }
        .fa-angle-up, .fa-angle-down {
          color: @red;
          font-size: 22px;
          margin: 0;
          padding: 0;
        }
        .multi-items {
          .collapse-header {
            cursor: pointer;
            .alert-title-container {
              // padding-top: 10px;
            }
          }
        }
        .alert-title-container {
          padding: 10px 0 10px 5px;
          .alert-title {
            margin: 0;
            letter-spacing: -1px;
          }
        }
        .multi-alert-container {
          .alert-title-container {
            padding: 1% 0% 1% 7%;
          }
          .button-styles {
            &.orange-btn {
              margin: 1.5% 15% 0 0;
            }
          }
        }
      }
    }
    &:hover { 
      box-shadow: 0px 0px 5px 0px @red inset;
    }
  }
}