@import "./dashboard/alert.less";

alert-center {
  margin-top: 10px;
  .col-md-4 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .title {
    font-size: 23px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 20px;
  }
}
