.admin-tools-list {
  .tools-title {
    font-size: 18px;
    padding: 3px 15px;
  }

  .tools-description {
    padding: 7px; 
    font-size: 12px
  }

  .tools-action-button {
    cursor: pointer;
  }
}

