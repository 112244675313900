ul.dropdown-menu {
  border-radius: 0;
  &.dropdown-orange {
    background-color: @orange;
    a {
      color: @white;
      &:hover {
        color: @orange;
      }
    }
  }
}