.vendor-renewal {
  .renewal-content {
    h2 {
      margin: 0 10px 10px 10px;
    }
    p {
      margin: 25px 10px 10px 10px;
    }
    .renewal-choices {
      max-width: 65%;
      border: 1px solid #e0e0e0;
      padding: 0 15px;
      margin-top: 35px;
      .input-group {
        margin: 15px 0;
        input[type="radio"] {
          width: 25px;
          height: 25px;
          margin-top: 0;
          vertical-align: middle;
        }
        label {
          font-weight: normal;
          margin-bottom: 0;
          margin-left: 35px;
          color: #333333;
          display: block;
        }
        &:after {
          content: "";
          clear: both;
          display: table;
        }
      }
    }
    .renewal-clients {
      margin-top: 40px;
      max-width: 85%;
      .darkRed {
        font-weight: bold;
        color: @dark-red;
        text-transform: uppercase;
      }
      .green {
        font-weight: bold;
        color: @green;
        text-transform: uppercase;
      }
      .normal {
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}
