.modal {
  .modal-dialog {
    width: 100%;
    position: fixed;
    transform: translate(-50%) !important;
    left: 50%;
    top: 30px;
    margin: 0 10px;
    .modal-content {
      border-radius: 0px;
      .modal-body {
        display: inline-block;
        width: 100%;
        padding: 20px 10px;
      }
      .edit-vendor.modal-body {
        padding: 20px;
      }
    }
  }
}

// DEPRECATED - Using Bootstrap modals now
.v-modal{
    width: 100%;
    height: 100%;
    z-index: 1051;
    position: absolute;
    top: 0;
}

.modalBackground{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1052;
}

.modalView{
    top: 150px;
    left: 10%;
    width: 80%;
    height: 95%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgb(255, 255, 255);
    z-index: 1053;
    position: absolute;
}

.fauxModal{
    left: 50%;
    transform: translate(-50%);
    border: solid 1px #ddd;
    padding: 20px;
}

.close-modal {
    cursor: pointer;
    z-index: 1060;
    font-size: 28px;
    color: @blue;
    -webkit-transition: color 0.15s ease-in-out;
    transition: color 0.15s ease-in-out;
    &:hover {
        color: @orange;
    }
}

.vendorSelect {
  tr.active {
    td {
      background-color: #0f4cad !important;
      color: white;
    }
  }
}

.attachToggle.attached {
  color: green;
}
.modal-dialog {
  overflow-y: initial !important;
  &.modal-clientWizard, .modal-vendorBid, .modal-awardBid {
    width: 85vw;
  }
  .modal-body {
    max-height: 95vh;
    overflow-y: auto;
    overflow-x: none;
  }
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .modal-dialog {
    &.modal-clientWizard {
      width: 60vw;
    }
    &.modal-vendorBid {
      width: 75vw;
    }
    &.modal-awardBid {
      width: 95vw;
    }
    .modal-changeVIVEStatus {
      height: 90vh;
    }
  }
}
/* X-Large devices (large desktops, 1400px and up) */
@media (min-width: 1400px) {
  .modal-dialog {
    &.modal-clientWizard {
      width: 50vw;
    }
    &.modal-vendorBid {
      width: 85vw;
    }
    &.modal-awardBid {
      width: 85vw;
    }
    &.modal-complianceSettings {
      width: 75vw;
    }
    .modal-changeVIVEStatus {
      height: 85vh;
    }
  }
}

@media (min-height: 450px) {
  .view-implementation-document-modal .modal-content {
    height: 80vh;
  }
}
@media (min-height: 650px) {
  .view-implementation-document-modal .modal-content {
    height: 75vh;
  }
}
@media (min-height: 800px) {
  .view-implementation-document-modal .modal-content {
    height: 70vh;
  }
}
