// New Client Site Details - .client-site-details-view
.client-site-details-view {
  .view-row {
    border: 1px solid #e0e0e0;
  }
  .spacer {
    padding: 20px;
  }
  .tab-container {
    .tab {
      cursor: pointer;
      background-color: @blue;
      color: #FFFFFF;
      text-align: center;
      padding: 15px 0;
      &.active {
        background-color: @orange;
        // Orange Active Triangle for Tab
        &::after {
          content: '';
          width: 0;
          height: 0;
          border-top: 25px solid transparent;
          border-bottom: 25px solid transparent;
          border-left: 25px solid @orange;
          position: absolute;
          top: 0;
          right: -25px;
          z-index: 1039;
        }
      }
    }
  }
  .tab-view-area {
    .vendor-details-box {
      .green-status {
        color: @green;
      }
      .red-status {
        color: @dark-red;
      }
      .toUpper {
        text-transform: uppercase;
      }
      .star-rating {
        color: @orange;
        display: block;
        font-size: 18px;
      }
      .table {
        margin-bottom: 0;
        tbody > tr > td {
          border-bottom-color: #e0e0e0;
          border-top-color: #e0e0e0;
          a {
            font-weight: bold;
            color: @blue;
            text-decoration: underline;
            &:hover {
              color: @orange;
            }
          }
        }
      }
      .tooltip.vendor-details {
        &.in {
          opacity: 0.8;
        }
        &.left {
          .tooltip-arrow {
            border-left-color: @blue;
          }
        }
        .tooltip-inner {
          text-align: left;
          max-width: 350px;
          background-color: @blue;
          color: #FFFFFF;
        }
      }
    }
    .add-border {
      border: 1px solid #e0e0e0;
    }
    .spacer {
      margin-left: 30px;
    }
    .site-map {
      font-family: "Rubik", Helvetica, Arial, sans-serif;
      h4 {
        font-weight: 400;
        margin: 0;
        font-size: 18px;
      }
      p {
        color: #999999;
        margin: 8px 0 0;
      }
      .address-details {
        border-top: 1px solid #e0e0e0;
        padding: 15px 10px 10px;
        .directions {
          a {
            color: #2196f3;
            text-decoration: none;
            &:hover {
              text-decoration: none;
            }
            .dir-icon {
              fill: #2196f3;
              width: 25px;
              height: 25px;
              display: block;
              margin: 0 auto;
            }
            span {
              text-align: center;
              display: block;
              margin-top: 5px;
              font-size: 11px;
            }
          }
        }
      }
    }
  }
  // VIDEO DROPDOWNS
  .dropdown-menu {
    width: 375px;
    margin: 0;
    padding: 0;
    li {
      cursor: pointer;
      text-transform: uppercase;
      background-color: #041E39;
      color: #FFFFFF;
      padding: 10px 10px;
      -webkit-transition: all 0.15s ease-in-out;
      transition: all 0.15s ease-in-out;
      &:hover {
        background-color: @orange;
      }
    }
  }
}
