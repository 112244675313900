.edit-site {
  a {
    font-weight: bold;
    color: #041E39;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: @orange;
    }
  }
  .input-group-btn {
    .btn {
      border-radius: 0 !important;
      line-height: 1.5;
      &.btn-orange-datepicker {
        color: #FFFFFF;
        background-color: @orange;
        &:hover {
          background-color: @blue;
        }
      }
    }
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
  }
  .vertical-border {
    border-left: #999 1px solid;
  }
  .error-label {
    color: @red;
  }
  select.ng-touched.ng-invalid,
  select.ng-dirty.ng-invalid,
  input.ng-touched.ng-invalid,
  input.ng-dirty.ng-invalid {
    color: @red;
    border-color: @red;
    -webkit-transition: box-shadow 0.15s ease-in-out;
    transition: box-shadow 0.15s ease-in-out;
    box-shadow: 0 0 3px 1px @red inset;
  }
  span.required {
    color: @red;
    font-size: inherit;
    padding-left: 0;
    text-align: inherit;
  }
}
