@import (reference) "./../colors.less";
.adjust-pricing-table {
  .heading-row {
    &>div {
      padding-top: 15px;
    }
    background-color: @orange; 
    color: @white; 
    font-weight: bold; 
    white-space: nowrap; 
    text-align: center; 
    height: 50px;
  }

  .pricing-row {
    &>div {
      text-align: center;
      padding-top: 12px;
      padding-bottom: 12px;
      input {
        margin-top: -7px;
        text-align: center;
      }
    }
  }
}