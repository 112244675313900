.quick-source {
  a {
    font-weight: bold;
    color: #041E39;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: @orange;
    }
  }
  .input-group-btn {
    .btn {
      border-radius: 0 !important;
      line-height: 1.5;
      &.btn-orange-datepicker {
        color: #FFFFFF;
        background-color: @orange;
        &:hover {
          background-color: @blue;
        }
      }
    }
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
  }
  .vertical-border {
    border-left: #999 1px solid;
  }
  .error-label {
    color: @red;
  }
  select.ng-touched.ng-invalid,
  select.ng-dirty.ng-invalid,
  input.ng-touched.ng-invalid,
  input.ng-dirty.ng-invalid {
    color: @red;
    border-color: @red;
    -webkit-transition: box-shadow 0.15s ease-in-out;
    transition: box-shadow 0.15s ease-in-out;
    box-shadow: 0 0 3px 1px @red inset;
  }
  span.required {
    color: @red;
    font-size: inherit;
    padding-left: 0;
    text-align: inherit;
  }
  .stepThreeA {
    .ui-select-bootstrap {
      margin: 10px 0 0 0;
    }
    .ui-select-bootstrap > .ui-select-match > .btn {
      text-align: center !important;
    }
    .ui-select-bootstrap .ui-select-choices-row.active > span {
      background-color: @blue;
    }
    .ui-select-bootstrap > .ui-select-match > .btn, .ui-select-bootstrap > input.ui-select-search {
      display: block;
      font-weight: normal;
      text-align: center;
      cursor: pointer;
      background-image: none;
      border: 1px solid #d0d0d0;
      white-space: nowrap;
      padding: 10px 0 10px 10px;
      font-size: inherit;
      line-height: 1;
      border-radius: 0;
      -webkit-transition: box-shadow 0.15s ease-in-out;
      transition: box-shadow 0.15s ease-in-out;
      min-height: 40px;
      &:hover {
        box-shadow: 0 0 3px 1px @vive-gray inset;
        background-color: #FFFFFF;
      }
      &:active {
        box-shadow: 0 0 3px 1px @vive-gray inset;
      }
      &:focus {
        box-shadow: 0 0 3px 1px @vive-gray inset;
      }
      .ui-select-match-text {
        line-height: 1;
        span {
          text-align: center;
        }
      }
    }
  }
}
