/* ==========================================================================
  Certificate
   ========================================================================== */

.certificate {
  padding-top : 2rem;
  font-family : 'Oswald', sans-serif;
  font-weight : 300;
  font-size   : 16px
}

.certificate .container {
  border : 25px solid #041E39
}

.certificate .img-responsive {
  margin-left  : auto;
  margin-right : auto
}

.certificate .bold {
  font-weight : 700
}

.certificate ul {
  list-style   : none;
  padding-left : 0
}

.certificate .heading {
  text-align : center
}

.certificate .heading h1 {
  color         : #f7931e;
  font-size     : 76px;
  margin-bottom : .2em
}

.certificate .heading h2 {
  font-weight : 300;
  font-size   : 31px
}

.certificate .heading ul {
  background-color : #5bbd50;
  text-transform   : uppercase;
  margin-bottom    : 0
}

.certification .heading ul li {
  color     : #041E39;
  font-size : 18px
}

.certificate .heading ul li:first-child {
  font-weight : 700;
  color       : #fff
}

.certificate .heading ul li:last-child {
  font-size : 24px
}

.certificate .heading .cert {
  padding-top : 15px
}

.certificate .heading .main {
  padding-top : 15px
}

.certificate .heading .issued {
  max-width    : 200px;
  margin-left  : auto;
  margin-right : auto
}

.certificate .heading .issued ul:first-child {
  padding-top : 15px
}

.certificate .heading .issued ul:last-child {
  padding-bottom : 15px
}

.certificate .info {
  margin-top : 30px;
  font-size  : 18px
}

.certificate .info dl dd {
  margin-bottom : 15px;
}

.certificate .compliance {
  margin-top : 30px
}

.certificate .compliance h2 {
  text-align     : center;
  color          : #5bbd50;
  text-transform : uppercase
}

.certificate .compliance h3 {
  margin-bottom  : .5em;
  font-family    : 'Oswald', sans-serif;
  text-transform : uppercase
}

.certificate .compliance h4 {
  font-family : 'Oswald', sans-serif
}

.certificate .compliance h5 {
  font-family : 'Oswald', sans-serif;
  font-weight : 300;
  font-size   : 16px
}

.certificate .compliance ul {
  padding-left : 10px
}

.certificate .footer {
  background-color : #fff;
  margin-top       : 30px
}

.certificate .footer .disclosure {
  background-color : #5bbd50
}

.certificate .footer .disclosure h5 {
  padding-top    : 10px;
  padding-bottom : 10px;
  font-size      : 16px;
  font-weight    : 300;
  font-family    : 'Oswald', sans-serif
}

.certificate .footer .disclaimer {
  margin : 15px 0
}

.certificate .footer .disclaimer h4 {
  font-family : 'Oswald', sans-serif;
  font-weight : 700;
  font-size   : 12px;
  margin-top  : 0
}

.certificate .footer .disclaimer p {
  font-family   : 'Oswald', sans-serif;
  font-size     : 12px;
  font-weight   : 300;
  margin-bottom : 0
}

.certificate .footer .logo {
  margin : 15px 0
}

.certificate {
  .strike {
    display     : block;
    text-align  : center;
    overflow    : hidden;
    white-space : nowrap
  }

  .strike > span {
    position : relative;
    display  : inline-block
  }

  .strike > span:before,
  .strike > span:after {
    content    : "";
    position   : absolute;
    top        : 50%;
    width      : 9999px;
    height     : 1px;
    background : #000
  }

  .strike > span:before {
    right        : 100%;
    margin-right : 15px
  }

  .strike > span:after {
    left        : 100%;
    margin-left : 15px
  }
}

@media (max-width : 1199px) and (min-width : 992px) {
  .certificate .heading h1 {
    font-size : 61px
  }

  .certificate .heading h2 {
    font-size : 25px
  }
}

@media (max-width : 991px) and (min-width : 768px) {
  .certificate .heading h1 {
    font-size : 46px
  }

  .certificate .heading h2 {
    font-size : 19px
  }
}

@media (max-width : 767px) {
  .certificate .heading h1 {
    font-size : 35px
  }

  .certificate .heading h2 {
    font-size     : 22px;
    margin-bottom : 20px
  }

  .certificate .heading img {
    max-width     : 150px;
    margin-bottom : 20px
  }

  .certificate .info img {
    margin-bottom : 10px
  }

  .certificate .compliance h2 {
    font-size : 26px
  }

  .certificate .compliance h3 {
    font-size : 20px
  }

  .certificate .compliance h5 {
    margin-bottom : 30px
  }

  .certificate .disclosure {
    margin-bottom : 30px
  }

  .certificate .logo img {
    max-width : 100px
  }

  .certificate .heading .issued {
    max-width    : 200px;
    margin-left  : auto;
    margin-right : auto
  }
}

