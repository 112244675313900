.vendor-signup {
  max-width: 75vw; 
  margin: 2% auto 0;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
  }
  .required-a {
    float: none;
    color: red;
    font-size: 14px;
  }
  .btn {
    width: 100%;
  }
  .error-label {
    color: #FF0000;
  }
  .error-input {
    color: #FF0000;
    border-color: #FF0000;
  }
  .privacy-link {
    strong {
      cursor: pointer;
      color: #041E39;
      text-decoration: underline;
      text-transform: uppercase;
      -webkit-transition: color 0.15s ease-in-out;
      transition: color 0.15s ease-in-out;    
      &:hover {
        color: @orange;
      }
    }
  } 
}
.terms-privacy {
  h5 {
    font-weight: bold;
  }
  ul {
    padding-left: 20px;
    li {
      margin: 5px 0;
    }
  }
}
