@import (reference) "./../colors.less";

.compliance-site-toggle {
  border: 2px solid @labelColor;
  border-radius: 8px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom: 0px;
  &.active {
    border: 2px solid @orange;
  }
}

.compliance-toggle-container {
  border-bottom: 2px solid @orange;
}