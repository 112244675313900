/* TOOLTIP OVERRIDES */
.tooltip {
  font-family: "Rubik",Helvetica,Arial,sans-serif;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 12px;
  &.right .tooltip-arrow {
    border-right-color: #e0e0e0;
  }
  .tooltip-inner {
    padding: 10px 12px;
    color: #000;
    text-align: center;
    background-color: #e0e0e0;
    border-radius: 0;
    box-shadow: 2px 2px 5px -2px #000000;
    ul {
      li:before {
        content: '\2013';
        margin-right: 5px;
        font-weight: bold;
      }
      text-align: left;
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }
}
// TOOLTIP FOR GRIDS
.tooltip.vendor-details {
  &.in {
    opacity: 0.8;
  }
  &.left {
    .tooltip-arrow {
      border-left-color: @blue;
    }
  }
  .tooltip-inner {
    text-align: left;
    max-width: 350px;
    background-color: @blue;
    color: #FFFFFF;
  }
}
/* GRID STYLES */
.viveGrid {
  // START SEARCH
  .searchbox {
    margin-bottom: 1.5%;
    label {
      margin-right: 5%;
    }
    input.wide-input {
      display: inline-block;
      max-width: 60%;
    }
  }
  // END SEARCH
  .add-vendor{
    .btn.btn-orange-thin {
      width: 100%;
      margin: 0 0 20px;
      padding: 13px 0;
    }
  }
  #ui-grid-twbs #ui-grid-twbs .form-horizontal .form-group:before,
  #ui-grid-twbs #ui-grid-twbs .form-horizontal .form-group:after,
  #ui-grid-twbs #ui-grid-twbs .btn-toolbar:before,
  #ui-grid-twbs #ui-grid-twbs .btn-toolbar:after,
  #ui-grid-twbs #ui-grid-twbs .btn-group-vertical > .btn-group:before,
  #ui-grid-twbs #ui-grid-twbs .btn-group-vertical > .btn-group:after {
    content: " ";
    display: table;
  }
  #ui-grid-twbs #ui-grid-twbs .form-horizontal .form-group:after,
  #ui-grid-twbs #ui-grid-twbs .btn-toolbar:after,
  #ui-grid-twbs #ui-grid-twbs .btn-group-vertical > .btn-group:after {
    clear: both;
  }
  .ui-grid {
    border: 1px solid #e0e0e0;
    box-sizing: content-box;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -o-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    font-size: 12px;
  }
  .ui-grid-vertical-bar {
    position: absolute;
    right: 0;
    width: 0;
  }
  .ui-grid-header-cell:not(:last-child) .ui-grid-vertical-bar,
  .ui-grid-cell:not(:last-child) .ui-grid-vertical-bar {
    width: 1px;
  }
  .ui-grid-scrollbar-placeholder {
    background-color: transparent;
  }
  .ui-grid-header-cell:not(:last-child) .ui-grid-vertical-bar {
    background-color: #e0e0e0;
  }
  .ui-grid-cell:not(:last-child) .ui-grid-vertical-bar {
    background-color: #e0e0e0;
  }
  .ui-grid-header-cell:last-child .ui-grid-vertical-bar {
    right: -1px;
    width: 1px;
    background-color: #e0e0e0;
  }
  .ui-grid-clearfix:before,
  .ui-grid-clearfix:after {
    content: "";
    display: table;
  }
  .ui-grid-clearfix:after {
    clear: both;
  }
  .ui-grid-invisible {
    visibility: hidden;
  }
  .ui-grid-contents-wrapper {
    position: relative;
    height: 100%;
    max-width: 100%;
    overflow: hidden;
  }
  .ui-grid-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  .ui-grid-top-panel-background {
    background: #ffffff;
  }
  .ui-grid-header {
    border-bottom: 0 solid transparent;
    box-sizing: border-box;
  }
  .ui-grid-top-panel {
    position: relative;
    overflow: hidden;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    background: #ffffff;
    border-radius: 0 0 0 0;
    background-clip: padding-box;
  }
  .ui-grid-header-viewport {
    overflow: hidden;
  }
  .ui-grid-header-canvas:before,
  .ui-grid-header-canvas:after {
    content: "";
    display: table;
    line-height: 0;
  }
  .ui-grid-header-canvas:after {
    clear: both;
  }
  .ui-grid-header-cell-wrapper {
    position: relative;
    display: table;
    box-sizing: border-box;
    border-bottom: 2px solid @orange;
    width: 100%;
    height: 100%;
  }
  .ui-grid-header-cell-row {
    display: table-row;
    position: relative;
  }
  .ui-grid-header-cell {
    position: relative;
    box-sizing: border-box;
    background-color: inherit;
    border-right: 1px solid #e0e0e0;
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 0;
    .ui-grid-cell-contents {
      padding: 5px;
    }
  }
  .ui-grid-header-cell:last-child {
    border-right: 0;
  }
  .ui-grid-header-cell .sortable {
    cursor: pointer;
  }
  .ui-grid-header-cell .ui-grid-sort-priority-number {
    margin-left: -8px;
  }
  .ui-grid-header .ui-grid-vertical-bar {
    top: 0;
    bottom: 0;
  }
  .ui-grid-column-menu-button {
    position: absolute;
    right: 1px;
    top: 0;
  }
  .ui-grid-column-menu-button .ui-grid-icon-angle-down {
    vertical-align: sub;
  }
  .ui-grid-column-menu-button-last-col {
    margin-right: 25px;
  }
  .ui-grid-column-menu {
    position: absolute;
  }
  /* Slide up/down animations */
  .ui-grid-column-menu .ui-grid-menu .ui-grid-menu-mid.ng-hide-add,
  .ui-grid-column-menu .ui-grid-menu .ui-grid-menu-mid.ng-hide-remove {
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -o-transition: all 0.1s linear;
    transition: all 0.1s linear;
    display: block !important;
  }
  .ui-grid-column-menu .ui-grid-menu .ui-grid-menu-mid.ng-hide-add.ng-hide-add-active,
  .ui-grid-column-menu .ui-grid-menu .ui-grid-menu-mid.ng-hide-remove {
    opacity: 0;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .ui-grid-column-menu .ui-grid-menu .ui-grid-menu-mid.ng-hide-add,
  .ui-grid-column-menu .ui-grid-menu .ui-grid-menu-mid.ng-hide-remove.ng-hide-remove-active {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  /* Slide up/down animations */
  .ui-grid-menu-button .ui-grid-menu .ui-grid-menu-mid.ng-hide-add,
  .ui-grid-menu-button .ui-grid-menu .ui-grid-menu-mid.ng-hide-remove {
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -o-transition: all 0.1s linear;
    transition: all 0.1s linear;
    display: block !important;
  }
  .ui-grid-menu-button .ui-grid-menu .ui-grid-menu-mid.ng-hide-add.ng-hide-add-active,
  .ui-grid-menu-button .ui-grid-menu .ui-grid-menu-mid.ng-hide-remove {
    opacity: 0;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .ui-grid-menu-button .ui-grid-menu .ui-grid-menu-mid.ng-hide-add,
  .ui-grid-menu-button .ui-grid-menu .ui-grid-menu-mid.ng-hide-remove.ng-hide-remove-active {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .ui-grid-filter-container {
    padding: 4px 10px;
    position: relative;
  }
  .ui-grid-filter-container .ui-grid-filter-button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }
  .ui-grid-filter-container .ui-grid-filter-button [class^="ui-grid-icon"] {
    position: absolute;
    top: 50%;
    line-height: 32px;
    margin-top: -16px;
    right: 10px;
    opacity: 0.66;
  }
  .ui-grid-filter-container .ui-grid-filter-button [class^="ui-grid-icon"]:hover {
    opacity: 1;
  }
  .ui-grid-filter-container .ui-grid-filter-button-select {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }
  .ui-grid-filter-container .ui-grid-filter-button-select [class^="ui-grid-icon"] {
    position: absolute;
    top: 50%;
    line-height: 32px;
    margin-top: -16px;
    right: 0px;
    opacity: 0.66;
  }
  .ui-grid-filter-container .ui-grid-filter-button-select [class^="ui-grid-icon"]:hover {
    opacity: 1;
  }
  input[type="text"].ui-grid-filter-input {
    margin: 5px 0;
    font-weight: normal;
    border: 1px solid #d0d0d0;
    background-clip: padding-box;
    width: 100%;
    height: 28px;
    padding: 0 5px;
    outline: none;
    border-radius: 0;
    -webkit-transition: box-shadow 0.15s ease-in-out;
    transition: box-shadow 0.15s ease-in-out;
    &:hover {
      box-shadow: 0 0 3px 1px @orange inset;
    }
    &:active {
      box-shadow: 0 0 3px 1px @orange inset;
    }
    &:focus {
      box-shadow: 0 0 3px 1px @orange inset;
    }
  }
  select.ui-grid-filter-select {
    padding: 0 5px;
    margin: 5px 0;
    border: 0;
    width: 95%;
    font-weight: normal;
    border: 1px solid #d0d0d0;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-right-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    -webkit-border-top-left-radius: 0;
    -moz-border-radius-topright: 0px;
    -moz-border-radius-bottomright: 0;
    -moz-border-radius-bottomleft: 0;
    -moz-border-radius-topleft: 0;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    -moz-background-clip: padding-box;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    height: 28px;
  }
  select.ui-grid-filter-select:hover {
    border: 1px solid #d0d0d0;
  }
  .ui-grid-filter-cancel-button-hidden select.ui-grid-filter-select {
    width: 100%;
  }
  .ui-grid-render-container {
    position: inherit;
    -webkit-border-top-right-radius: 0;
    -webkit-border-bottom-right-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -webkit-border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -moz-border-radius-bottomright: 0px;
    -moz-border-radius-bottomleft: 0px;
    -moz-border-radius-topleft: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0;
    -moz-background-clip: padding-box;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .ui-grid-render-container:focus {
    outline: none;
  }
  .ui-grid-render-container-body {
    .ui-grid-viewport {
      min-height: 20px;
      position: relative;
      overflow-y: scroll !important;
      -webkit-overflow-scrolling: touch;
      .ui-grid-canvas {
        width: 100%;
        position: relative;
        padding-top: 1px;
      }
    }
  }
  .ui-grid-viewport:focus {
    outline: none !important;
  }
  .ui-grid-row {
    clear: both;
  }
  .ui-grid-row:nth-child(odd) .ui-grid-cell {
    background-color: #ffffff;
  }
  .ui-grid-row:nth-child(even) .ui-grid-cell {
    background-color: #efefef;
  }
  .ui-grid-row {
    .ui-grid-cell {
      border-bottom: 1px solid #efefef;
      border-right: 0;
    }

    .ui-grid-cell.ui-grid-cell-missing-required {
      color: white;
      background-color: #AD0516;
    }

    .ui-grid-cell.ui-grid-cell-duplicate-client-users-in-upload-list {
      color: white;
      background-color: #fbb51d;
    }

    .ui-grid-cell.ui-grid-cell-duplicate-taxid,
    .ui-grid-cell.ui-grid-cell-duplicate-client-users-in-database {
      background-color: #eed202;
    }

    .ui-grid-cell.ui-grid-cell-assign-sites-client-users {
      background-color: #91cf68;
    }

    & :hover {
      .ui-grid-cell {
        background: #041E39;
        border-bottom: #041E39;
        color: #FFFFFF !important;
        transition: all 0.25s ease-in-out;
      }
      .ui-grid-cell.ui-grid-row-header-cell {
        color: #333333 !important;
      }
    }
    &.ui-grid-row-selected {
      .ui-grid-cell {
        background: #041E39;
        border-bottom: #041E39;
        color: #FFFFFF !important;
        transition: all 0.25s ease-in-out;
      }
      .ui-grid-cell.ui-grid-row-header-cell {
        color: #333333 !important;
      }
    }
  }
  .ui-grid-no-row-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 10%;
    background: #ffffff;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-right-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    -webkit-border-top-left-radius: 0;
    -moz-border-radius-topright: 0px;
    -moz-border-radius-bottomright: 0;
    -moz-border-radius-bottomleft: 0;
    -moz-border-radius-topleft: 0;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    -moz-background-clip: padding-box;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #e0e0e0;
    font-size: 2em;
    text-align: center;
  }
  .ui-grid-no-row-overlay > * {
    position: absolute;
    display: table;
    margin: auto 0;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.66;
  }
  /* This file contains variable declarations (do not remove this line) */
  /*-- VARIABLES (DO NOT REMOVE THESE COMMENTS) --*/
  /**
  * @section Grid styles
  */
  /**
  * @section Header styles
  */
  /** @description Colors for header gradient */
  /**
  * @section Grid body styles
  */
  /** @description Colors used for row alternation */
  /**
  * @section Sort arrow colors
  */
  /**
  * @section Scrollbar styles
  */
  /**
  * @section font library path
  */
  /*-- END VARIABLES (DO NOT REMOVE THESE COMMENTS) --*/
  .ui-grid-cell {
    overflow: hidden;
    float: left;
    background-color: inherit;
    border-right: 1px solid;
    border-color: #e0e0e0;
    box-sizing: border-box;
  }
  .ui-grid-cell:last-child {
    border-right: 0;
  }
  .ui-grid-header-cell {
    .ui-grid-cell-contents {
      line-height: inherit;
    }
  }
  .ui-grid-cell-contents {
    padding: 5px 10px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 20px;
    height: 100%;
    text-align: center;
  }
  .ui-grid-cell-contents-hidden {
    visibility: hidden;
    width: 0;
    height: 0;
    display: none;
  }
  .ui-grid-row .ui-grid-cell.ui-grid-row-header-cell {
    background-color: #ffffff;
    border-bottom: solid 1px #e0e0e0;
  }
  .ui-grid-selection-row-header-buttons [class^="ui-grid-icon"] {
    opacity: 1 !important;
  }
  .ui-grid-render-container-left {
    .ui-grid-viewport {
      min-height: 255px;
      .ui-grid-canvas {
        padding-top: 0;
      }
    }
    .ui-grid-header-canvas {
      max-width: 100%;
      // min-height: 75px;
      .ui-grid-header-cell-wrapper {
        max-width: 65%;
        .ui-grid-cell-contents {
          padding: 5px 5px 51px 5px;
        }
      }
    }
  }
  .ui-grid-row .ui-grid-disable-selection
  {
    .ui-grid-cell-contents {
      padding: 5px;
    }
  } 
  .ui-grid-footer-panel-background {
    background: #ffffff;
  }
  .ui-grid-footer-panel {
    position: relative;
    border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
    overflow: hidden;
    font-weight: bold;
    background: #ffffff;
    -webkit-border-top-right-radius: 0;
    -webkit-border-bottom-right-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    -webkit-border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -moz-border-radius-bottomright: 0;
    -moz-border-radius-bottomleft: 0;
    -moz-border-radius-topleft: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    -moz-background-clip: padding-box;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .ui-grid-grid-footer {
    float: left;
    width: 100%;
    font-weight: bold;
    padding: 5px 10px;
    border-top: 1px solid #e0e0e0;
  }
  .ui-grid-footer-viewport {
    overflow: hidden;
  }
  .ui-grid-footer-canvas {
    position: relative;
  }
  .ui-grid-footer-canvas:before,
  .ui-grid-footer-canvas:after {
    content: "";
    display: table;
    line-height: 0;
  }
  .ui-grid-footer-canvas:after {
    clear: both;
  }
  .ui-grid-footer-cell-wrapper {
    position: relative;
    display: table;
    box-sizing: border-box;
    height: 100%;
  }
  .ui-grid-footer-cell-row {
    display: table-row;
  }
  .ui-grid-footer-cell {
    overflow: hidden;
    background-color: inherit;
    border-right: 1px solid;
    border-color: #e0e0e0;
    box-sizing: border-box;
    display: table-cell;
  }
  .ui-grid-footer-cell:last-child {
    border-right: 0;
  }

  .ui-grid-pager-control {
    .ui-grid-pager-max-pages-number {
      vertical-align: middle;
      margin: 5px;
      abbr[title], abbr[data-original-title] {
        margin-right: 3px;
        cursor: auto;
        border: 0 solid transparent;
        text-decoration: none;
        vertical-align: baseline;
      }
    }
    input {
      border-radius: 0;
      box-shadow: 0 0 0 0;
      border: 1px solid #e0e0e0;
    }
    button {
      height: 25px;
      min-width: 26px;
      border: 1px solid @blue;
      border-radius: 0;
      user-select: none;
      color: #FFFFFF;
      background-color: @blue;
      border-color: #cccccc;
      .first-bar {
        width: 10px;
        border-left: 2px solid #FFFFFF;
        margin-top: -6px;
        height: 12px;
        margin-left: -3px;
      }
      .last-bar {
        width: 10px;
        border-left: 2px solid #FFFFFF;
        margin-top: -6px;
        height: 12px;
        margin-left: 1px;
      }
      .first-triangle {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 8.7px 5px 0;
        border-color: transparent #FFFFFF transparent transparent;
        margin-left: 2px;
      }
      .last-triangle {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 8.7px;
        border-color: transparent transparent transparent #FFFFFF;
        margin-left: -1px;
      }
    }
    button[disabled] {
      background-color: #e0e0e0;
      border: 1px solid #e0e0e0;
      & :hover {
        background-color: #e0e0e0;
      }
      .first-bar {
        width: 10px;
        border-left: 2px solid #bbbbbb;
        margin-top: -6px;
        height: 12px;
        margin-left: -3px;
      }
      .last-bar {
        width: 10px;
        border-left: 2px solid #bbbbbb;
        margin-top: -6px;
        height: 12px;
        margin-left: 1px;
      }
      .first-triangle {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 8.7px 5px 0;
        border-color: transparent #bbbbbb transparent transparent;
        margin-left: 2px;
      }
      .last-triangle {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 8.7px;
        border-color: transparent transparent transparent #bbbbbb;
        margin-left: -1px;
      }
    }
  }
  .ui-grid-pager-row-count-picker {
    select {
      box-shadow: 0 0 0 0;
      border-radius: 0;
      vertical-align: middle;
      border: 1px solid #e0e0e0;
    }
    .ui-grid-pager-row-count-label {
      margin-top: 0;
      vertical-align: middle;
      margin: 5px;
    }
  }
  .ui-grid-pager-count-container {
    .ui-grid-pager-count {
      abbr[title], abbr[data-original-title] {
        margin-right: 0;
        cursor: auto;
        border: 0 solid transparent;
        text-decoration: none;
        vertical-align: baseline;
      }
    }
  }
  input[type="text"].ui-grid-filter-input:hover {
    border: 1px solid #e0e0e0;
  }
  .ui-grid-menu-button {
    z-index: 2;
    position: absolute;
    right: -1px;
    top: -1px;
    background: @blue;
    border: 1px solid @blue;
    cursor: pointer;
    height: 25px;
    font-weight: normal;
    transition: all 0.15s ease-in-out;
  }
  .ui-grid-menu-button:hover, .ui-grid-menu-button:focus {
    background: @orange;
    border: 1px solid @orange;
  }
  .ui-grid-menu-button .ui-grid-icon-container {
    margin-top: 3px;
  }
  .ui-grid-menu-button .ui-grid-menu {
    right: 0;
  }
  .ui-grid-menu-button .ui-grid-menu .ui-grid-menu-mid {
    overflow: auto;
    border: 1px solid @orange;
    box-shadow: 0px 3px 8px -2px #666666;
  }
  .ui-grid-menu {
    z-index: 2;
    position: absolute;
    padding: 0 10px 20px 10px;
    cursor: pointer;
    box-sizing: border-box;
  }
  .ui-grid-menu .ui-grid-menu-inner {
    background: @orange;
    border: 0px solid transparent;
    position: relative;
    white-space: nowrap;
    border-radius: 0px;
    box-shadow: 0 0 0 0;
  }
  .ui-grid-menu .ui-grid-menu-inner ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .ui-grid-menu .ui-grid-menu-inner ul li {
    padding: 0px;
  }
  .ui-grid-menu .ui-grid-menu-inner ul li button {
    min-width: 100%;
    padding: 8px;
    text-align: left;
    background: transparent;
    border: none;
    font-size: 91%;
    color: #ffffff;
  }
  .ui-grid-menu .ui-grid-menu-inner ul li button:hover,
  .ui-grid-menu .ui-grid-menu-inner ul li button:focus {
    box-shadow: 0 0 0 0;
    background-color: @blue;
  }
  .ui-grid-menu .ui-grid-menu-inner ul li button.ui-grid-menu-item-active {
    box-shadow: inset 0 0 0 0;
    background-color: @blue;
  }
  .ui-grid-menu .ui-grid-menu-inner ul li:not(:last-child) > button {
    border-bottom: 0 solid transparent;
  }
  .ui-grid-sortarrow {
    right: 5px;
    position: absolute;
    width: 20px;
    top: 0;
    bottom: 0;
    background-position: center;
  }
  .ui-grid-sortarrow.down {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  [class^="ui-grid-icon"]:before,
  [class*=" ui-grid-icon"]:before {
    font-family: "ui-grid";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .4em;
    /* you can be more comfortable with increased icons size */
    font-size: 100%;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  }
  .ui-grid-icon-blank::before {
    width: 1em;
    content: ' ';
  }
  .ui-grid-icon-plus-squared:before {
    content: '\c350';
  }
  .ui-grid-icon-minus-squared:before {
    content: '\c351';
  }
  .ui-grid-icon-search:before {
    content: '\c352';
  }
  .ui-grid-icon-cancel:before {
    content: '\c353';
  }
  .ui-grid-icon-info-circled:before {
    content: '\c354';
  }
  .ui-grid-icon-lock:before {
    content: '\c355';
  }
  .ui-grid-icon-lock-open:before {
    content: '\c356';
  }
  .ui-grid-icon-pencil:before {
    content: '\c357';
  }
  .ui-grid-icon-down-dir:before {
    content: '\c358';
  }
  .ui-grid-icon-up-dir:before {
    content: '\c359';
  }
  .ui-grid-icon-left-dir:before {
    content: '\c35a';
  }
  .ui-grid-icon-right-dir:before {
    content: '\c35b';
  }
  .ui-grid-icon-left-open:before {
    content: '\c35c';
  }
  .ui-grid-icon-right-open:before {
    content: '\c35d';
  }
  .ui-grid-icon-angle-down:before {
    content: '\c35e';
  }
  .ui-grid-icon-filter:before {
    content: '\c35f';
  }
  .ui-grid-icon-sort-alt-up:before {
    content: '\c360';
  }
  .ui-grid-icon-sort-alt-down:before {
    content: '\c361';
  }
  .ui-grid-icon-ok:before {
    content: '\c362';
  }
  .ui-grid-icon-menu:before {
    content: '\c363';
    color: #ffffff
  }
  .ui-grid-icon-indent-left:before {
    content: '\e800';
  }
  .ui-grid-icon-indent-right:before {
    content: '\e801';
  }
  .ui-grid-icon-spin5:before {
    content: '\ea61';
  }
  /*
  * RTL Styles
  */
  .ui-grid[dir=rtl] .ui-grid-header-cell,
  .ui-grid[dir=rtl] .ui-grid-footer-cell,
  .ui-grid[dir=rtl] .ui-grid-cell {
    float: right !important;
  }
  .ui-grid[dir=rtl] .ui-grid-column-menu-button {
    position: absolute;
    left: 1px; 
    top: 0;
    right: inherit;
  }
  .ui-grid[dir=rtl] .ui-grid-cell:first-child,
  .ui-grid[dir=rtl] .ui-grid-header-cell:first-child,
  .ui-grid[dir=rtl] .ui-grid-footer-cell:first-child {
    border-right: 0;
  }
  .ui-grid[dir=rtl] .ui-grid-cell:last-child,
  .ui-grid[dir=rtl] .ui-grid-header-cell:last-child {
    border-right: 1px solid #e0e0e0;
    border-left: 0;
  }
  .ui-grid[dir=rtl] .ui-grid-header-cell:first-child .ui-grid-vertical-bar,
  .ui-grid[dir=rtl] .ui-grid-footer-cell:first-child .ui-grid-vertical-bar,
  .ui-grid[dir=rtl] .ui-grid-cell:first-child .ui-grid-vertical-bar {
    width: 0;
  }
  .ui-grid[dir=rtl] .ui-grid-menu-button {
    z-index: 2;
    position: absolute;
    left: 0;
    right: auto;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    min-height: 27px;
    font-weight: normal;
  }
  .ui-grid[dir=rtl] .ui-grid-menu-button .ui-grid-menu {
    left: 0;
    right: auto;
  }
  .ui-grid[dir=rtl] .ui-grid-filter-container .ui-grid-filter-button {
    right: initial;
    left: 0;
  }
  .ui-grid[dir=rtl] .ui-grid-filter-container .ui-grid-filter-button [class^="ui-grid-icon"] {
    right: initial;
    left: 10px;
  }
  /*
    Animation example, for spinners
  */
  .ui-grid-animate-spin {
    -moz-animation: ui-grid-spin 2s infinite linear;
    -o-animation: ui-grid-spin 2s infinite linear;
    -webkit-animation: ui-grid-spin 2s infinite linear;
    animation: ui-grid-spin 2s infinite linear;
    display: inline-block;
  }
  @-moz-keyframes ui-grid-spin {
    0% {
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -moz-transform: rotate(359deg);
      -o-transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @-webkit-keyframes ui-grid-spin {
    0% {
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -moz-transform: rotate(359deg);
      -o-transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @-o-keyframes ui-grid-spin {
    0% {
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -moz-transform: rotate(359deg);
      -o-transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @-ms-keyframes ui-grid-spin {
    0% {
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -moz-transform: rotate(359deg);
      -o-transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @keyframes ui-grid-spin {
    0% {
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -moz-transform: rotate(359deg);
      -o-transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  // CUSTOM STYLES - CELL CLASSES
  .cellDarkRed {
    font-weight: bold;
    color: @dark-red;
  }
  .imageLink {
    color: #333333;
    &:hover {
      color: #FFFFFF;
    }
  }
  .checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    height: 31px;
    width: 17px;
  }

  .checkbox > input {
    height: 25px;
    width: 25px !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    outline: none;
    transition-duration: 0.3s;
    background-color: #040044;
    cursor: pointer;
    margin: 0 !important;
  }
  .checkbox > input + span::before {
    content: '';
    display: block;
    text-align: center;
    color: #FFFFFF;
    position: relative;
    left: 3px;
    top: -3px;
    font-size: 22px;
  }
  .checkbox > input:checked {
    border: 1px solid #FFFFFF;
    background-color: #040044;
  }
  .checkbox > input:checked + span::before {
    content: '\2713';
    display: block;
    text-align: center;
    color: #FFFFFF;
    position: relative;
    left: 3px;
    top: -3px;
    font-size: 22px;
  }
  .checkbox > input:active {
    border: 2px solid #FFFFFF;
  }
}
