// New Client Vendor Details - .vendor-details-view
.red-status {
  color: @dark-red;
}

.vendor-details-view {
  .view-row {
    border: 1px solid #e0e0e0;
  }
  .spacer {
    padding: 20px;
  }
  .tab-container {
    .tab {
      cursor: pointer;
      background-color: @blue;
      color: #FFFFFF;
      text-align: center;
      padding: 15px 0;
      &.active {
        background-color: @orange;
        // Orange Active Triangle for Tab
        &::after {
          content: '';
          width: 0;
          height: 0;
          border-top: 25px solid transparent;
          border-bottom: 25px solid transparent;
          border-left: 25px solid @orange;
          position: absolute;
          top: 0;
          right: -25px;
          z-index: 1039;
        }
      }
    }
  }
  .tab-view-area {
    .contacts-list,
    .insurance-list,
    .licenses-list,
    .sites-list {
    }
    .add-border {
      border: 1px solid #e0e0e0;
    }
    .vendor-map {
      font-family: "Rubik", Helvetica, Arial, sans-serif;
      h4 {
        font-weight: 400;
        margin: 0;
        font-size: 18px;
      }
      p {
        color: #999999;
        margin: 8px 0 0;
      }
      .address-details {
        border-top: 1px solid #e0e0e0;
        padding: 15px 10px 10px;
        .directions {
          a {
            color: #2196f3;
            text-decoration: none;
            &:hover {
              text-decoration: none;
            }
            .dir-icon {
              fill: #2196f3;
              width: 25px;
              height: 25px;
              display: block;
              margin: 0 auto;
            }
            span {
              text-align: center;
              display: block;
              margin-top: 5px;
              font-size: 11px;
            }
          }
        }
      }
    }
    .vendor-details-box {
      .green-status {
        color: @green;
      }
      .red-status {
        .red-status();
      }
      .yellow-status {
        color: #ffa500;
      }
      .toUpper {
        text-transform: uppercase;
      }
      .star-rating {
        color: @orange;
        display: block;
        font-size: 18px;
      }
      .table {
        margin-bottom: 0;
        tbody > tr > td {
          border-bottom-color: #e0e0e0;
          border-top-color: #e0e0e0;
          a {
            font-weight: bold;
            color: @blue;
            text-decoration: underline;
            &:hover {
              color: @orange;
            }
          }
        }
      }
      .tooltip.vendor-details {
        &.in {
          opacity: 0.8;
        }
        &.left {
          .tooltip-arrow {
            border-left-color: @blue;
          }
        }
        .tooltip-inner {
          text-align: left;
          max-width: 350px;
          background-color: @blue;
          color: #FFFFFF;
        }
      }
    }
    .vendor-compliance-style {
      .compliance-container {
        margin-left: 25px;
      }
      .compliance-text {
        text-align: left;
        margin: 0 !important;
        padding: 8px 0;
      }
      .compliance-row {
        & > div {
          margin-bottom: 10px;
          select, input {
            margin: 5px 0;
          }
        }
        .compliance-pass-fail {
          text-align: left;
          i.fa {
            padding: 11px 0;
          }
        }
        .row-border-bottom {
          border-bottom: 1px solid #e0e0e0;
          border-left: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0;
          margin: 0 2%;
          max-width: 96%;
          p {
            margin: 10px 0;
          }
        }
        .row-header {
          background-color: @blue;
          padding-top: 1%;
          padding-bottom: 1%;
          max-width: 96%;
          margin: 0 2%;
          label {
            color: #FFF;
          }
        }
      }
      .vendorCompliance.admin {
        margin-top: 15px;
        label {
          display: block;
          text-align: center;
        }
        .row {
          margin-top: 15px;
          margin-bottom: 15px;
          p {
            text-align: center;
          }
        }
      }
    }
    .vendor-review {
      hr.seperator {
        margin: 10px 0 20px;
      }
      h3 {
        font-weight: 700;
        font-size: 22px;
        margin: 30px 0 0;
      }
      h4 {
        font-size: 21px;
        margin-bottom: 0;
      }
      .green {
        color: #01c801;
      }
      .red {
        color: #d80000;
      }
      i.fa-star, i.fa-star-o {
        margin: 0;
        color: @orange;
      }
      .numOfReviews {
        font-size: 12px;
      }
      .overall-rating {
        max-width: 45%;
        margin: 30px auto;
        text-align: center;
      }
      .specific-ratings {
        margin-left: 25px;
        h3 {
          text-align: center;
        }
        .vendor-customer-service, .vendor-professionalism {
          span {
            i.fa.fa-star, i.fa.fa-star-o {
              cursor: default;
            }
          }
        }
        .user-ratings {
          .user-ratings-header {
            padding: 10px;
            text-align: center;
          }
          .user-item-container {
            border: 1px solid #999999;
            overflow-y: auto;
            overflow-x: hidden;
            height: 480px;
            .user-item {
              text-align: center;
              padding: 10px;
              cursor: pointer;
              border-top: 1px solid #E0E0E0;
              -webkit-transition: all 0.15s ease-in-out;
              transition: all 0.15s ease-in-out;
              &:last-child {
                border-bottom: 1px solid #E0E0E0;
              }
              &:hover {
                background-color: #041E39;
                border-color: #041E39;
                color: #FFFFFF;
              }
              span {
                display: block;
                font-size: 4px;
                i {
                  margin: 0 1px;
                  color: @orange;
                  cursor: default;
                }
              }
            }
          }
        }
      }
    }
  }
}
