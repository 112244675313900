.files {
  h4 {
    margin-top: 20px;
  }
  .orange-text, .red-text {
    font-weight: bold;
    text-transform: uppercase;
    display: inline-block;
    &:hover {
      cursor: pointer;
    }
  }
  .orange-text {
    color: @orange;
  }
  .red-text {
    color: @red;
  }
  .list-group-item {
    border: 1px solid #E0E0E0;
    &:hover {
      background-color: #E0E0E0;
    }
    &:first-child {
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
    }
    &:last-child {
      margin-bottom: 0;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
  }
}
